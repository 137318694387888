<template>
  <v-main class="grey lighten-3">
    <template v-if="error">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-alert type="error" outlined text v-if="error" class="col-3">{{ $t('dashboard.error') }}</v-alert>
        </v-row>
      </v-container>
    </template>
    <v-container v-else class="mt-5" :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col
            cols="12"
            md="8"
            offset-md="2"
        >
          <v-row
              class="fill-height"
              align-content="center"
              justify="center"
              v-if="dashboardLoading"
          >
            <v-col
                class="text-subtitle-1 text-center"
                cols="12"
            >
              {{ $t('dashboard.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-container class="pa-4" fluid="fluid" grid-list-lg="grid-list-lg">
              <v-layout wrap="wrap">
                <v-flex xs12="xs12">
                  <v-layout wrap="wrap">
                    <p class="text-h5 pl-0 ml-0">{{ $t('dashboard.signatures.label') }}</p>
                    <v-flex xs12="xs12">
                      <v-subheader class="text-h6 pl-0 ml-0">{{ $t('dashboard.signatures.collected') }}</v-subheader>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(1, null)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.collected.total }}</div>
                          {{ $t('dashboard.signatures.total') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(1, 1)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.collected.standard }}</div>
                          {{ $t('dashboard.signatures.standard') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(1, 2)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.collected.extended }}</div>
                          {{ $t('dashboard.signatures.extended') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>

                    <v-flex xs12="xs12">
                      <v-subheader class="text-h6 pl-0 ml-0">{{ $t('dashboard.signatures.pending') }}</v-subheader>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(0, null)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.pending.total }}</div>
                          {{ $t('dashboard.signatures.total') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(0, 1)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.pending.standard }}</div>
                          {{ $t('dashboard.signatures.standard') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs4="xs4">
                      <v-card class="text-xs-center" height="100%" @click="toggleFilter(0, 2)">
                        <v-card-text>
                          <div class="display-1 mb-2">{{ signatureStats.pending.extended }}</div>
                          {{ $t('dashboard.signatures.extended') }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs12="xs12">
                      <template v-if="filters.signed !== null || filters.type !== null">
                        <v-row>
                          <v-col cols="6">
                            <v-subheader class="text-h6 pl-0 ml-0">{{ $t('dashboard.signatures.tableForGroupLabel') }}</v-subheader>
                          </v-col>
                          <v-col cols="6" class="text-right">
                            <v-btn small @click="resetFilters" class="mt-2"><v-icon left>mdi mdi-close</v-icon> {{ $t('dashboard.signatures.resetFilters') }}</v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-subheader class="text-h6 pl-0 ml-0">{{ $t('dashboard.signatures.tableLabel') }}</v-subheader>
                      </template>
                      <v-divider></v-divider>

                      <v-flex xs12="xs12">
                        <v-row>
                          <v-select v-model="companyCode" class="col-6 m-0 p-0" :items="companies" item-text="name" item-value="code" label="Company" @change="departmentCode = null; loadTableData()"></v-select>
                          <v-select v-model="departmentCode" class="col-6 m-0 p-0" :items="departments" item-text="name" item-value="code" label="Department" @change="loadTableData"></v-select>
                        </v-row>
                      </v-flex>

                      <v-text-field
                          v-model="userSearch"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          class="col-6 ml-0 pl-0"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12="xs12">
                      <v-data-table
                          dense
                          multi-sort
                          :headers="signageTableHeaders"
                          :items="signageTableData"
                          item-key="name"
                          class="elevation-1"
                          :search="userSearch"
                          :loading="tableIsLoading"
                      >
                        <template v-slot:[`item.is_manager`]="{ item }">
                          <v-icon
                              :color="getColor(item.is_manager)"
                          >{{ getIcon(item.is_manager) }}</v-icon>
                        </template>
                        <template v-slot:[`item.has_logged_in`]="{ item }">
                          <v-icon
                              :color="getColor(item.has_logged_in)"
                          >{{ getIcon(item.has_logged_in) }}</v-icon>
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                          {{ getDocType(item.type) }}
                        </template>
                        <template v-slot:[`item.saved_to`]="{ item }">
                          <v-btn v-if="item.saved_to"  icon><a class="icon-link" :href="`${sharepointBaseUrl + item.saved_to}`" target="_blank"><v-icon>mdi-file</v-icon></a></v-btn>
                          <v-icon v-else>mdi mdi-minus</v-icon>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
import dashboardService from "@/services/dashboardService";
import companiesWithDepartments from "@/domain/Companies";

export default {
  name: 'Dashboard',
  data() {
    return {
      dashboardLoading: false,
      moreDashboardLoading: false,
      error: false,
      sharepointBaseUrl: process.env.VUE_APP_SHAREPOINT_BASE_URL,
      filters: {
        signed: null,
        type: null,
      },
      signatureStats: null,
      signageTableData: null,
      signageTableHeaders: [
        {
          text: 'Full name',
          align: 'start',
          sortable: true,
          value: 'full_name',
        },
        {text: this.$t('dashboard.signatures.table.isManager'), value: 'is_manager', align: 'center'},
        {text: this.$t('dashboard.signatures.table.loggedIn'), value: 'has_logged_in', align: 'center'},
        {text: this.$t('dashboard.signatures.table.docType'), value: 'type', align: 'center'},
        {text: this.$t('dashboard.signatures.table.file'), value: 'saved_to', align: 'center'},
      ],
      userSearch: '',
      docType: {
        standard: this.$t('dashboard.signatures.standard'),
        extended: this.$t('dashboard.signatures.extended'),
      },
      tableIsLoading: false,
      companyCode: null,
      departmentCode: null,
    }
  },
  async created() {
    console.log('created');
    try {
      this.dashboardLoading = true;
      this.signatureStats = await dashboardService.getStats();
      await this.loadTableData();
    } catch (e) {
      this.error = true;
      this.dashboardLoading = false;
    }
    this.dashboardLoading = false;
  },
  methods: {
    getColor(flag) {
      return parseInt(flag) ? 'green' : 'primary'
    },
    getIcon(flag) {
      return parseInt(flag) ? 'mdi mdi-check' : 'mdi mdi-minus'
    },
    getDocType(type) {
      if(type === null) return '-';
      let val = parseInt(type);
      return val === 1 ? this.docType.standard : this.docType.extended;
    },
    async loadTableData() {
      this.tableIsLoading = true;
      this.filters.companyCode = this.companyCode;
      this.filters.departmentCode = this.departmentCode;
      this.signageTableData = await dashboardService.getSignageTable(this.filters);
      this.tableIsLoading = false;
    },
    toggleFilter(signed, type) {
      this.filters.signed = signed;
      this.filters.type = type;
      this.loadTableData();
    },
    resetFilters() {
      this.filters.type = null;
      this.filters.signed = null;
      this.filters.companyCode = null;
      this.filters.departmentCode = null;
      this.companyCode = null;
      this.departmentCode = null;
      this.userSearch = '';
      this.loadTableData();
    },
  },
  computed: {
    companies() {
      // map companiesWithDepartments to get only companies names and their codes
      return companiesWithDepartments.map(company => {
              return {
                name: company.name,
                code: company.code,
              }
            });
    },
    departments() {
      return this.companyCode === null ? [] : companiesWithDepartments.find(company => company.code === this.companyCode).departments;
    },
  },
}
</script>

<style>
.icon-link {
  text-decoration: none!important;
}
</style>