<template>
  <layout name="BaseLayout">
    <v-main class="grey lighten-3">
      <v-container class="mt-5" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row>
          <back-to-button :sticky="$vuetify.breakpoint.mdAndUp"/>
          <v-col
              cols="12"
              md="9"
              xl="10"
          >
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="policyLoading"
            >
              <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
              >
                {{ $t('policy.loading') }}
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-else class="policy-body">
              <v-col cols="12">
                <v-sheet rounded>
                  <v-container class="pa-5" v-if="policyText">
                    <div v-html="policyText" id="document-text"></div>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
          <back-to-button v-if="$vuetify.breakpoint.smAndDown"/>
        </v-row>
      </v-container>
    </v-main>
  </layout>
</template>

<script>
import { mapState } from "vuex";
import layout from "@/components/layout/Layout";
import htmlContentService from "@/services/htmlContentService";
import BackToButton from "@/components/policy/BackToButton";

export default {
  name: "Policy",
  components: {BackToButton, layout},
  computed: {
    ...mapState({
      language: state => (state.auth.user ? state.auth.user.language : null),
    }),
  },
  data() {
    return {
      policyText: null,
      policyLoading: false,
    }
  },
  watch: {
      language: function() {
        this.loadPolicy();
      }
  },
  async created() {
    await this.loadPolicy();
  },
  methods: {
    async loadPolicy() {
      this.policyLoading = true;
      this.policyText = await htmlContentService.getPolicy(this.$route.params.name);
      this.policyLoading = false;
      this.$vuetify.goTo(64)
    },
  }
}
</script>

<style lang="scss">
.policy-menu {
  .v-list-item__title {
    font-size: 1.1rem!important;
    color: #38466c;
    font-weight: bold!important;
  }

  .v-list-item__icon {
    margin-right: 15px!important;
  }
}

.policy-body {
  font-size: 1rem;

  h2, h3, h4 {
    padding-top: 10px;
    padding-bottom: 20px;
    color: #38466c;

    span {
      padding-right: 20px;
    }
  }

  p {
    text-align: justify;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  table {
    margin-bottom: 20px;
  }

  thead {
    background-color: #4d78c6;

    th {
      font-size: 1rem;
      text-align: center;
      color: #fff;
      font-weight: bold;
      line-height: 2.2rem;
      vertical-align: middle;
    }
  }

  tbody td {
    background-color: #dae3f3;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.9rem;
    text-align: justify;

    &.definition {
      text-align: center;
      font-weight: bold;
      padding: 0 1rem 0 1rem;
    }
  }

  ul, ol {
    margin-bottom: 15px;
  }

  .coc-lvl3 {
    color: #0e0e0e;
    font-style: italic;
    font-size: 16px;

    .num {
      padding-right: 10px;
    }
  }
}
</style>
