export const SET_PRODUCTS = 'SET_PRODUCTS';
export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const CLEAR_LOCATION = 'CLEAR_LOCATION';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_PRODUCT_QUANTITY = 'ADD_PRODUCT_QUANTITY';
export const EMPTY_CART = 'EMPTY_CART';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const FETCH_USER_LIGHT_REQUEST = 'FETCH_USER_LIGHT_REQUEST';
export const FETCH_USER_LIGHT_SUCCESS = 'FETCH_USER_LIGHT_SUCCESS';
export const FETCH_USER_LIGHT_FAILURE = 'FETCH_USER_LIGHT_FAILURE';
