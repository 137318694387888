export default {
    login: {
        title: 'Starfsmannavefur Samherja',
        hint: 'Innskráning með rafrænum skilríkjum',
        phoneNumberLabel: 'Símanúmer',
        send: 'Senda',
        confirmInfoTitle: 'Staðfestingarkóði sendur',
        confirmInfo: 'Ef kóðinn hér að ofan passar við þann sem þú sérð á símaskjánum þínum, vinsamlegast staðfestu auðkenni þitt með því að slá inn sPIN1 kóðann þinn.',
        timeRemaining: 'Tími eftir',
        phoneRequired: 'Símanúmer er krafist',
        wrongNumber: 'Samþykkt símanúmerasnið er: +354 xxx xxxx',
        loginSuccessful: 'Innskráning <strong>tókst</strong>. Beinir áfram ...',
        logout: 'Útskráning',
        languageToggle: 'English',
        testEnvironmentText: 'Þetta er <strong>prófunarumhverfi</strong>',
    },
    news: {
        pageTitle: 'Fyrirtækjafréttir - Starfsmannavefur Samherja',
        linkTitle: 'Fyrirtækjafréttir',
        readMore: 'Lestu meira',
        companyRelated: 'Almennar fréttir',
        loading: 'Hleður nýjustu fréttum...',
        loadMore: 'Hlaða meira',
        loadingMore: 'Hleður fleiri fréttir',
        categories: 'Fréttaflokkar',
        backToList: 'Aftur á fréttalista',
        error: 'Villa við að hlaða fréttum.',
    },
    store: {
        pageTitle: 'Verslun - Starfsmannavefur Samherja',
        linkTitle: 'Verslun',
        basketEmpty: 'Karfan þín er tóm',
        qtyPluralInBasket: 'vörur í körfu',
        qtySingularInBasket: 'vara í körfu',
        storeLocation: 'Verslun staðsetning',
        emptyCart: 'Tóm vagn',
        checkout: 'Greiðslu',
    },
    coc: {
        pageTitle: 'Hátternisreglur - Starfsmannavefur Samherja',
        linkTitle: 'Hátternisreglur',
        loading: 'Hleður texta skjalsins...',
        sideMenu: {
            title: 'Hlítingarnefnd',
            text: 'Hátternisreglur',
            policiesLabel: 'Stefnur',
            additionalPoliciesLabel: 'Aðrar reglur og verklagsreglur',
            reportingConcernsLabel: 'Tilkynna hugsanleg brot',
            reportingConcernsTitle: 'Hvernig á að tilkynna áhyggjuefni?',
            policies: {
                'business-partners': 'Hátternisreglur Samherja hf. fyrir viðskiptaaðila',
                hr: 'Mannauðsstefna Samherja hf',
                safety: 'Stefna Samherja hf. í öryggis, heilbrigðis- og vinnuverndarmálum',
                drugs: 'Reglur Samherja hf. um vímuefnalausan vinnustað',
                violence: 'Stefna og viðbragðsáætlun Samherja hf. gegn einelti, kynferðislegri og kynbundinni áreitni og ofbeldi',
                'human-rights': 'Mannréttindastefna Samherja hf',
                'gender-equality': 'Jafnréttisáætlun Samherja hf',
                privacy: 'Persónuverndarstefna vegna starfsfólks Samherja hf',
                'social-responsibility': 'Stefna um samfélagslega ábyrgð og sjálfbærni Samherja hf',
                environmental: 'Umhverfisstefna Samherja hf',
                'responsible-fishing': 'Reglur Samherja hf. um ábyrgar fiskveiðar',
                'prevention-of-corruption': 'Verklagsreglur Samherja hf. til að fyrirbyggja spillingu, mútugreiðslur og peningaþvætti',
                'gifts-and-hospitality': 'Verklagsreglur Samherja hf. um gjafir og gestrisni',
                'violations-procedures': 'Verklagsreglur Samherja hf. vegna tilkynninga um hugsanleg brot',
                'credit-cards': 'Verklagsreglur Samherja hf. um greiðslukort',
                customers: 'Persónuverndarstefna Samherja hf. vegna viðskiptavina',
                'job-applicants': 'Persónuverndarstefna vegna umsækjenda um störf hjá Samherja hf',
            },
            digitalSignage: 'Rafræn undirritun',
        },
        signDocument: 'Skrifaðu undir þetta skjal',
        preparingSignage: 'Undirbúningur rafrænnar undirritunar skjala',
    },
    policy: {
        backToCoc: 'aftur í Hátternisreglur',
        pageTitle: 'Hátternisreglur - Starfsmannavefur Samherja',
        linkTitle: 'Hátternisreglur',
        loading: 'Hleður texta skjalsins...',
    },
    education: {
        linkTitle: 'Menntun',
        mainDirectoryLabel: 'Heimaskrá',
        selectDirectory: 'Veldu möppu',
        loading: 'Hleður skrá',
    },
    forms: {
        linkTitle: 'Eyðublöð',
        listTitle: 'Available forms',
        submitLabel: 'Submit form',
        confirmationTitle: 'Are you sure?',
        confirmationText: 'This action cannot be undone.',
        proceed: 'Proceed',
        cancel: 'Cancel',
        successText: 'Eyðublað vistað',
        gifts: {
            title: 'Gifts reporting',
            form: {
                title: 'Short gift description',
                from: 'Received from',
                to: 'Received by',
                value: 'Estimated value',
                currency: 'Currency',
                comments: 'Other comments (optional)',
            },
        },
    },
    error: {
        network: 'Netvilla - líklegast er ekki hægt að ná í þjóninn.',
        unauthorized: 'Óheimilt - því miður tókst ekki að staðfesta skilríki þín.',
        validation: {
            required: 'Vinsamlegast gefðu upp gildi',
        },
    }
}
