export default {
    login: {
        title: 'Samherji\'s Employee Portal',
        hint: 'Login with Mobile ID',
        phoneNumberLabel: 'Phone number',
        send: 'Send',
        confirmInfoTitle: 'Verification code sent',
        confirmInfo: 'If the code above matches the one you see on your phone screen, please verify your identity by entering your sPIN1 code.',
        timeRemaining: 'Time remaining',
        phoneRequired: 'Phone number is required',
        wrongNumber: 'The accepted phone number format is: +354 xxx xxxx',
        loginSuccessful: 'Login <strong>successful</strong>. Redirecting...',
        logout: 'Logout',
        languageToggle: 'Íslenska',
        testEnvironmentText: 'This is a <strong>TEST</strong> environment',
    },
    news: {
        pageTitle: 'Company News - Samherji\'s employee website',
        linkTitle: 'Company News',
        readMore: 'Read more',
        companyRelated: 'Company related',
        loading: 'Loading latest news...',
        loadMore: 'Load more',
        loadingMore: 'Loading more',
        categories: 'Categories',
        backToList: 'Back to list',
        error: 'Error loading news.',
    },
    store: {
        pageTitle: 'Store - Samherji\'s employee website',
        linkTitle: 'Store',
        basketEmpty: 'Basket is empty',
        qtyPluralInBasket: 'products in basket',
        qtySingularInBasket: 'product in basket',
        storeLocation: 'Company Store location',
        emptyCart: 'Tóm vagn',
        checkout: 'Greiðslu',
    },
    coc: {
        pageTitle: 'Code of Conduct - Starfsmannavefur Samherja',
        linkTitle: 'Zasady postępowania',
        loading: 'Ładuję treść dokumentu...',
        sideMenu: {
            title: 'System zgodności',
            text: 'Zasady postępowania',
            policiesLabel: 'Dodatkowe dokumenty',
            policies: {
                hr: 'Samherji\'s Human Resource Policy',
                safety: 'Samherji\'s Safety, Health and Occupational Policy',
                drugs: 'Samherji\'s Rules on Drug and Alcohol Free Working Environment',
                violence: 'Samherji\'s Policy and Contingency Plan against Bullying, Sexual and Gender-based Harassment and Violence',
                'human-rights': 'Samherji\'s Human Rights Policy',
                'gender-equality': 'Samherji\'s Equality Plan',
                privacy: 'Samherji\'s Data Privacy Policy regarding Employees',
                'social-responsibility': 'Samherji\'s Policy regarding Social Responsibility and Sustainability',
                environmental: 'Samherji\'s Environmental Policy',
                'responsible-fishing': 'Samherji\'s Rules on Responsible Fisheries',
                'violations-procedures': 'Samherji\'s Procedure for Reporting Concerns',
            },
            digitalSignage: 'Podpis elektroniczny',
        },
        signDocument: 'Podpisz ten dokument elektronicznie',
        preparingSignage: 'Przygotowywanie dokumentu do podpisu...',
    },
    policy: {
        backToCoc: 'Zasady postępowania',
        pageTitle: 'Zasady postępowania - Samherji Employee Portal',
        linkTitle: 'Zasady postępowania',
        loading: 'Ładuję treść dokumentu...',
    },
    education: {
        linkTitle: 'Edukacja',
        mainDirectoryLabel: 'Katalog główny',
        selectDirectory: 'Wybierz katalog',
        loading: 'Ładuję plik',
    },
    forms: {
        linkTitle: 'Forms',
    },
    error: {
        network: 'Network error - server is most likely unreachable.',
        unauthorized: 'Unauthorized - we\'re sorry, but your credentials have not been successfully verified.',
    }
}
