import {
    ADD_PRODUCT_TO_CART,
    ADD_PRODUCT_QUANTITY,
    EMPTY_CART,
} from "@/store/mutationTypes";

import {LOCATION_AKUREYRI, LOCATION_DALVIK} from "@/api/shop";


const initialState = {
    cart: {
        [LOCATION_AKUREYRI]: [],
        [LOCATION_DALVIK]: [],
    }
};

const getters = {
    getCart: state => state.cart,
};

const actions = {
    addProductToCart(context, product) {
        if(product.quantity < 1) {
            return;
        }
        const cartItem = context.state.cart[product.location].find(item => item.id === product.id);
        if(!cartItem) {
            context.commit(ADD_PRODUCT_TO_CART, product);
        } else {
            context.commit(ADD_PRODUCT_QUANTITY, { cartItem: cartItem, quantity: product.quantity } );
        }
    },
    emptyCart(context, location) {
        context.commit(EMPTY_CART, location);
    }
}

const mutations = {
    [ADD_PRODUCT_TO_CART](state, product) {
        state.cart[product.location].push(product);
    },
    [ADD_PRODUCT_QUANTITY](state, payload) {
        payload.cartItem.quantity = payload.cartItem.quantity + payload.quantity;
    },
    [EMPTY_CART](state, location) {
        state.cart[location] = [];
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
