<template>
  <v-footer
      color="indigo darken-3"
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >
      <v-col
          class="indigo darken-1 py-4 text-center white--text"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Samherji hf.</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>
