<template>
  <v-card
      class="mx-auto"
  >

    <v-img v-if="image !== null"
        height="250"
        :src="url"
    ></v-img>

    <router-link :to="{ name: 'SinglePost', params: { id: id } }" class="card-link">
      <v-card-title>{{ title }}</v-card-title>
    </router-link>

    <v-card-text>
      <v-row
          align="center"
          class="mx-0"
      >
        <div class="grey--text mb-5">
          {{ publishedAt }}
        </div>
        <v-chip v-if="category" pill small class="mb-5 ml-5">{{ category }}</v-chip>
      </v-row>

      <div class="ellipsis" v-html="intro"></div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn
          color="deep-purple lighten-2"
          text
          @click="onReadMore(id)"
      >
        {{ $t('news.readMore') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "NewsListItem",
  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    publishedAt: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    intro: {
      type: String,
      default: ''
    },
  },
  methods: {
    onReadMore(newsId) {
      this.$router.push({ name: 'SinglePost', params: { id: newsId } });
    }
  },
  computed: {
    url() {
      return this.imgBaseUrl + this.image;
    }
  },
  data() {
    return {
      imgBaseUrl: `${process.env.VUE_APP_API_URL}/images/`
    }
  }
}
</script>

<style scoped>
.ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.card-link {
  text-decoration: none;
  color: inherit;
}
</style>
