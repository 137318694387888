<template>
  <v-card
      class="mx-auto mb-12"
  >
    <v-img
        height="250"
        :src="image"
    ></v-img>

    <v-card-title>{{ name }}</v-card-title>

    <v-card-text>
      <v-row
          align="center"
          class="mx-0"
      >
      </v-row>

      <div class="my-4 text-subtitle-1">
        {{ new Intl.NumberFormat().format(price) }} ISK • <strong>{{ inStock }}</strong> available
      </div>

      <div>{{ description }}</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <add-to-cart @add-to-cart="$emit('add-to-cart', $event)" :disabled="inStock === 0" :max-quantity="inStock"></add-to-cart>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddToCart from "@/components/product/AddToCart";
export default {
  name: "ProductsListItem",
  components: {AddToCart},
  props: {
    name: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: 0
    },
    inStock: {
      type: Number,
      default: 0
    },
  },
}
</script>

<style>

</style>
