<template>
  <v-main class="grey lighten-3 flex flex-fill flex-column flex-grow-1 fill-height education">
    <PdfViewer ref="pdfViewer" :document-path="documentPath" :document-title="documentTitle"/>
    <v-container class="mt-5 mb-10">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="indigo white--text text-h5">
              {{ $t('education.linkTitle') }}
            </v-card-title>
            <v-row
                class="pa-4 fl"
                justify="space-between"
            >
              <v-col v-if="loadingDirectories" md="5" cols="12" class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
              </v-col>
              <v-col v-else md="5" cols="12">
                <template v-if="!directories || directories.length < 1">
                  <v-list>
                    <v-list-item-group
                        color="primary"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-folder</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('education.mainDirectoryLabel') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </template>
                <v-treeview
                    item-key="path"
                    :active.sync="active"
                    :items="directories"
                    activatable
                    color="warning"
                    transition
                    return-object
                    @update:active="fetchFilesList"
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon v-if="!item.children">
                      mdi-folder
                    </v-icon>
                  </template>
                </v-treeview>
              </v-col>

              <v-divider vertical v-if="$vuetify.breakpoint.smAndUp"></v-divider>
              <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly" class="mb-0 pb-0">
                <v-divider horizontal></v-divider>
                <v-subheader v-if="this.active[0]" class="mb-0 pb-0">Files in {{ this.active[0].name }}</v-subheader>
              </v-col>

              <v-col md="7" cols="12">
                <v-scroll-y-transition mode="out-in">
                  <template v-if="loadingFiles">
                    <div class="text-center">
                      <v-progress-circular
                          :size="50"
                          color="primary"
                          indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                  <template v-else>
                    <div
                        v-if="!active || active.length < 1"
                        class="text-h6 grey--text text--lighten-1 font-weight-light"
                        style="align-self: center;"
                    >
                      {{ $t('education.selectDirectory') }}
                    </div>
                    <template v-else>
                      <v-list
                          dense
                          :key="active[0].path"
                      >
                        <v-list-item-group
                            v-model="selectedFile"
                            color="primary"
                        >
                          <v-list-item
                              v-for="(file, i) in files"
                              :key="i"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-file</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title v-text="file.name" @click="openPdf(file)"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </template>
                  </template>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import educationService from "@/services/educationService";
import PdfViewer from "@/components/education/PdfViewer";

export default {
  name: "Education",
  components: {PdfViewer},
  created() {
    this.fetchDirectoriesTree();
  },
  data() {
    return {
      active: [],
      directories: [],
      files: [],
      selectedFile: null,
      loadingDirectories: false,
      loadingFiles: false,
      documentPath: '',
      documentTitle: '',
    }
  },
  methods: {
    async fetchFilesList() {
      this.loadingFiles = true;
      this.files = await educationService.getFilesList(this.active[0].path);
      this.loadingFiles = false;
    },
    async fetchDirectoriesTree() {
      this.loadingDirectories = true;
      this.directories = await educationService.getDirectoriesTree();
      this.loadingDirectories = false;
    },
    openPdf(file) {
      this.documentPath = this.active[0].path + file.path;
      this.documentTitle = file.name;
      this.$refs.pdfViewer.open();
    }
  }
}
</script>

<style lang="scss">
.education {
  .v-treeview-node__root {
    cursor: pointer !important;
  }

  .v-treeview-node__content > * {
    display: flex;

    .mdi::before {
      padding-bottom: 2px;
    }
  }
}
</style>