import apiService from './apiService';

export default {
    getDocumentPath(relativePath) {
        return `${process.env.VUE_APP_API_URL}/api/education/file?f=${relativePath}`
    },

    async getDirectoriesTree() {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/education/tree`,
            {},
            { withAuth: true }
        );
        return response.data;
    },

    async getFilesList(directoryPath = null) {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/education/files`,
            { d : directoryPath },
            { withAuth: true }
        );
        return response.data;
    },
};
