import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import is from './is'
import pl from './pl'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'is',
  fallbackLocale: 'en',
  messages: {
    en, is, pl
  }
})
