<template>
  <iframe id="isign-gateway" width="100%" height="500px"
          :src="`${gatewayUrl}/signing/${signingToken}?access_token=${signerToken}`">
  </iframe>
</template>

<script>
export default {
  props: {
    signingToken: {
      type: String,
      default: ''
    },
    signerToken: {
      type: String,
      default: ''
    },
  },
  name: "DokobitIframe",
  data() {
    return {
      gatewayUrl: process.env.VUE_APP_DOKOBIT_GATEWAY_URL,
    }
  },
}
</script>

<style scoped>

</style>
