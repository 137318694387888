<template>
    <v-card class="login-card col-md-5 col-sm-10 col-xs-11 col-lg-4 my-4"
            style="transition: height 0.3s cubic-bezier(0.54, 0.03, 0.35, 0.8) 0s; height: auto;">
      <v-card-text class="text-center"
                   style="transition: height 0.3s cubic-bezier(0.54, 0.03, 0.35, 0.8) 0s; height: auto;">
        <v-btn
            x-small
            color="#4f46e5"
            outlined
            dark
            class="language-toggle"
            @click="toggleLanguage"
        >
          {{ $t('login.languageToggle') }}
        </v-btn>
        <img src="/assets/sam-no-bg.png"/>
        <h4>{{ $t('login.title') }}</h4>
        <v-alert
      v-if="isTest"
      dense
      border="left"
      type="warning"
      v-html="$t('login.testEnvironmentText')"
    >

    </v-alert>
        <p class="login-hint" v-if="!isAuthPending">{{ $t('login.hint') }}</p>
        <v-alert class="mb-0 text-left login-alert" v-if="authError" border="left" dense text type="error">
          {{ authError }}
        </v-alert>
        <template v-if="!authError && isAuthPending && controlCode">
          <h3 class="mb-5" key="sdasf">{{ $t('login.confirmInfoTitle') }}</h3>
          <v-card style="border-top: 2px solid #4f46e5">
            <h1 class="py-6" style="font-size: 2.2em; letter-spacing: 0.1em">{{ controlCode }}</h1>
            <span class="time-remaining">{{ $t('login.timeRemaining') }}: {{ timeRemaining }}</span>
          </v-card>
          <p class="mt-3" style="font-weight: bold">{{ $t('login.confirmInfo') }}</p>
        </template>
        <v-form ref="loginForm" @submit="onSendClick($event)" class="d-flex flex-column justify-center" align="center"
                justify="center" v-model="phoneValid"
                style="transition: height 0.3s cubic-bezier(0.54, 0.03, 0.35, 0.8) 0s; height: auto;">
          <v-text-field
              ref="phoneNumberInput"
              v-model="phoneNumber"
              outlined
              :loading="isAuthPending"
              :readonly="isAuthPending"
              :rules="[validateNumber]"
              validate-on-blur
              prepend-inner-icon="mdi-cellphone"
              name="phoneNumber"
              :label="$t('login.phoneNumberLabel')"
              type="text"
              class="phoneNumber pt-5" style=""
              prefix="+354"
          ></v-text-field>
          <template v-if="!authSuccess">
            <v-btn type="submit" :disabled="isAuthPending" class="mb-6 py-6" color="primary" @click="onSendClick"
                   key="btn">
              {{ $t('login.send') }}
            </v-btn>
          </template>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
const axios = require('axios');
import {mapActions} from 'vuex';

export default {
  name: 'Login',

  data: () => ({
    isTest: false,
    isAuthPending: false,
    phoneValid: false,
    phoneNumber: null,
    authError: null,
    authAttemptToken: null,
    controlCode: null,
    authIntervalId: null,
    authTimeoutIntervalId: null,
    timeRemaining: 120,
    jwtToken: null,
    authSuccess: false,
  }),
  watch: {
    authAttemptToken(newValue, oldValue) {
      if (newValue === null) {
        clearInterval(this.authIntervalId);
        this.authIntervalId = null;
        return;
      }
      if (oldValue === null) {
        this.authIntervalId = setInterval(() => {
          this.checkAuthStatus();
        }, 3000);
        this.authTimeoutIntervalId = setInterval(() => {
          this.updateRemainingTime();
        }, 1000);
      }
    }
  },
  mounted() {
    if(process.env.NODE_ENV === 'development') {
      this.isTest = true;
    }
    this.$nextTick(() => {
      setTimeout(() => {
        if (typeof this.$refs.phoneNumberInput !== 'undefined') {
          this.$refs.phoneNumberInput.$refs.input.focus();
        }
      })
    })
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    toggleLanguage() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'is';
        return;
      }
      this.$i18n.locale = 'en';
    },
    validateNumber(val) {
      if (!val) {
        return this.$t('login.phoneRequired');
      }
      let rawVal = val.trim().replace(' ', '');
      if (rawVal.indexOf('+') !== rawVal.lastIndexOf('+')) {
        return this.$t('login.wrongNumber');
      }
      if (!rawVal.match(/^(?=.*[0-9])[- +0-9]+$/)) {
        return this.$t('login.wrongNumber');
      }
      if (rawVal.startsWith('+')) {
        if (rawVal.length < 11 || rawVal.length > 13) {
          return this.$t('login.wrongNumber');
        }
      } else {
        if (rawVal.length < 7 || rawVal.length > 9) {
          return this.$t('login.wrongNumber');
        }
      }
      return true;
    },
    async onSendClick(event) {
      event.preventDefault();
      await this.$refs.loginForm.validate();
      if (!this.phoneValid) {
        this.$refs.phoneNumberInput.$refs.input.blur();
        return;
      }
      this.authAttemptToken = null;
      this.controlCode = null;
      this.authError = null;
      this.isAuthPending = true;
      await this.beginAuth();
    },
    onAuthError(error) {
      this.isAuthPending = false;
      this.authError = error;
      this.authAttemptToken = null;
      this.controlCode = null;
      this.timeRemaining = 120;
      clearInterval(this.authIntervalId);
      clearInterval(this.authTimeoutIntervalId);
    },
    async beginAuth() {
      try {
        let phoneNumber = this.phoneNumber.trim().replace(' ', '');
        if (!phoneNumber.startsWith('+')) {
          phoneNumber = '+354' + phoneNumber
        }
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/auth`, {
          phone_number: phoneNumber,
        });
        this.authAttemptToken = response.data.token;
        this.controlCode = response.data.control_code;
      } catch (error) {
        if (typeof error.response === 'undefined') {
          this.onAuthError(this.$t('error.network'));
          return;
        }
        if (error.response.status === 403) {
          this.onAuthError(error.response.data)
          return;
        }
        this.onAuthError(error.response.body)
      }
    },
    async checkAuthStatus() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/auth/status?token=`, {
          params: {
            token: this.authAttemptToken,
          }
        });
        if (response.data.status === 'waiting') {
          return;
        }
        if (response.data.status === 'ok') {
          await this.onAuthSuccess(response.data);
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.onAuthError(this.$t('error.unauthorized'));
          return;
        }
        this.onAuthError(error.response.data);
      }
    },
    async onAuthSuccess(data) {
      this.authAttemptToken = null;
      this.controlCode = null;
      clearInterval(this.authIntervalId);
      clearInterval(this.authTimeoutIntervalId);
      this.timeRemaining = 120;
      this.jwtToken = data.token;
      this.isAuthPending = false;
      this.authSuccess = true;
      window.localStorage.setItem('accessToken', data.token);
      window.localStorage.setItem('refreshToken', data.refreshToken);
      await this.authenticate({
        token: data.token,
        fullName: data.fullName,
        country: data.country,
        phoneNumber: this.phoneNumber,
        language: data.language,
      });
    },
    updateRemainingTime() {
      if (this.timeRemaining > 0) {
        this.timeRemaining = this.timeRemaining - 1;
      } else {
        clearInterval(this.authTimeoutIntervalId);
        this.timeRemaining = 120;
      }
    },
    ...mapActions({
      authenticate: 'auth/authenticate',
    })
  }
};
</script>

<style lang="scss">

.theme--light.v-application {
  background: #fafafa !important;
}

body {
  background: #fff;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.v-application {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.phoneNumber .v-input__slot {
  background-color: #fff !important;
}

.login-hint {
  color: rgba(75, 85, 99, 1);
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

h4 {
  padding-bottom: 20px;
  color: rgba(17, 24, 39, 1);
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  font-weight: 800;
  letter-spacing: 0.00735em;
}

.login-card {
  border: 4px solid #e5e7eb !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  background: #f0f0f0 !important;
  padding: 0 12px 0 12px;
}

.time-remaining {
  font-size: 1.2em;
  padding-bottom: 1em;
  display: block;
  color: #38466c;
  font-weight: bold;
}

.language-toggle {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-enter {
  opacity: 0 !important;
}

.slide-leave-to {
  opacity: 1 !important;
}
</style>
