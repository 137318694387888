export default {
    login: {
        title: 'Samherji\'s Employee Portal',
        hint: 'Login with Mobile ID',
        phoneNumberLabel: 'Phone number',
        send: 'Send',
        confirmInfoTitle: 'Verification code sent',
        confirmInfo: 'If the code above matches the one you see on your phone screen, please verify your identity by entering your sPIN1 code.',
        timeRemaining: 'Time remaining',
        phoneRequired: 'Phone number is required',
        wrongNumber: 'The accepted phone number format is: +354 xxx xxxx',
        loginSuccessful: 'Login <strong>successful</strong>. Redirecting...',
        logout: 'Logout',
        languageToggle: 'Íslenska',
        testEnvironmentText: 'This is a <strong>TEST</strong> environment',
    },
    dashboard: {
        pageTitle: 'Dashboard - Samherji\'s employee website',
        linkTitle: 'Dashboard',
        loading: 'Loading information...',
        error: 'Error loading data.',
        signatures: {
            label: 'Code of Conduct signatures',
            collected: 'Collected',
            pending: 'Pending',
            total: 'Total',
            standard: 'Standard',
            extended: 'Extended',
            tableLabel: 'All signings',
            tableForGroupLabel: 'Signings for selected group',
            companyDepartment: 'Company / Department',
            table: {
              isManager: 'Is manager?',
              loggedIn: 'Logged in?',
              file: 'File',
              docType: 'Document type',
            },
            resetFilters: 'Reset filters',
        },
    },
    news: {
        pageTitle: 'Company News - Samherji\'s employee website',
        linkTitle: 'Company News',
        readMore: 'Read more',
        companyRelated: 'Company related',
        loading: 'Loading latest news...',
        loadMore: 'Load more',
        loadingMore: 'Loading more',
        categories: 'Categories',
        backToList: 'Back to list',
        error: 'Error loading news.',
    },
    store: {
        pageTitle: 'Store - Samherji\'s employee website',
        linkTitle: 'Store',
        basketEmpty: 'Basket is empty',
        qtyPluralInBasket: 'products in basket',
        qtySingularInBasket: 'product in basket',
        storeLocation: 'Company Store location',
        emptyCart: 'Tóm vagn',
        checkout: 'Greiðslu',
    },
    coc: {
        pageTitle: 'Code of Conduct - Starfsmannavefur Samherja',
        linkTitle: 'Code of Conduct',
        loading: 'Loading document text...',
        sideMenu: {
            title: 'Compliance System',
            text: 'Code of Conduct',
            policiesLabel: 'Policies',
            additionalPoliciesLabel: 'Other rules and procedures',
            reportingConcernsLabel: 'Report a concern',
            reportingConcernsTitle: 'How to report a concern?',
            policies: {
                'business-partners': 'Samherji\'s Business Partner Code of Conduct',
                hr: 'Samherji\'s Human Resource Policy',
                safety: 'Samherji\'s Safety, Health and Occupational Policy',
                drugs: 'Samherji\'s Rules on Drug and Alcohol Free Working Environment',
                violence: 'Samherji\'s Policy and Contingency Plan against Bullying, Sexual and Gender-based Harassment and Violence',
                'human-rights': 'Samherji\'s Human Rights Policy',
                'gender-equality': 'Samherji\'s Equality Plan',
                privacy: 'Samherji\'s Data Privacy Policy regarding Employees',
                'social-responsibility': 'Samherji\'s Policy regarding Social Responsibility and Sustainability',
                environmental: 'Samherji\'s Environmental Policy',
                'responsible-fishing': 'Samherji\'s Rules on Responsible Fisheries',
                'prevention-of-corruption': 'Samherji’s Procedure for the Prevention of Corruption, Bribery and Money Laundering',
                'gifts-and-hospitality': 'Samherji’s Gifts and Hospitality Procedure',
                'violations-procedures': 'Samherji\'s Procedure for Reporting Concerns',
                'credit-cards': 'Procedure regarding Credit Cards',
                customers: 'Data Privacy Policy regarding Customers',
                'job-applicants': 'Data Privacy Policy regarding Job Applicants',
            },
            digitalSignage: 'Digital signage',
        },
        signDocument: 'Sign this document digitally',
        preparingSignage: 'Preparing document for digital signage',
    },
    policy: {
        backToCoc: 'Code of Conduct',
        pageTitle: 'Code of Conduct - Samherji Employee Portal',
        linkTitle: 'Code of Conduct',
        loading: 'Loading document text...',
    },
    education: {
        linkTitle: 'Education',
        mainDirectoryLabel: 'Home',
        selectDirectory: 'Select a directory',
        loading: 'Loading file',
    },
    forms: {
        linkTitle: 'Forms',
        listTitle: 'Available forms',
        submitLabel: 'Submit form',
        confirmationTitle: 'Are you sure?',
        confirmationText: 'This action cannot be undone.',
        proceed: 'Proceed',
        cancel: 'Cancel',
        successText: 'Form saved',
        gifts: {
            title: 'Gifts reporting',
            form: {
                title: 'Short gift description',
                from: 'Received from',
                to: 'Received by',
                value: 'Estimated value',
                currency: 'Currency',
                comments: 'Other comments (optional)',
            },
        },
    },
    error: {
        network: 'Network error - server is most likely unreachable.',
        unauthorized: 'Unauthorized - we\'re sorry, but your credentials have not been successfully verified.',
        validation: {
            required: 'Please provide a value',
        },
    }
}
