<template>
  <v-main class="grey lighten-3">
    <v-container class="mt-5" :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col
            cols="12"
            md="3"
            lg="3"
        >
          <v-sheet
              rounded="lg"
              :class="{ sticky: $vuetify.breakpoint.mdAndUp, 'coc-menu' : true }"
          >
            <v-list
                nav
                dense
                class="rounded"
                color="white"
            >
              <v-subheader class="text-h6">{{ $t('coc.sideMenu.title') }}</v-subheader>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title @click="$vuetify.goTo('#document-text')"
                                     v-text="$t('coc.sideMenu.text')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-menu top offset-x>
                <template v-slot:activator="{ on }">
                  <v-list-item link v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('coc.sideMenu.policiesLabel') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <v-list>
                  <v-list-item link :to="`/code-of-conduct/policies/${item.link}`"
                               v-for="(item, index) in filteredPolicies"
                               :key="index"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu top offset-x v-if="isManager">
                <template v-slot:activator="{ on }">
                  <v-list-item link v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('coc.sideMenu.additionalPoliciesLabel') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <v-list>
                  <v-list-item link :to="`/code-of-conduct/policies/${item.link}`"
                               v-for="(item, index) in additionalPolicies"
                               :key="index"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title @click="$vuetify.goTo('#signage')"
                                     v-text="$t('coc.sideMenu.digitalSignage')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-dialog
                  v-model="reportingConcernsVisible"
                  content-class="v-dialog--custom"
              >
                <template v-slot:activator="{ on }">
                  <v-list-item link v-on="on" @click="loadReportingConcerns">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('coc.sideMenu.reportingConcernsLabel') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-card>
                  <template v-if="reportingConcernsLoading">
                    <v-row
                        class="fill-height pa-5"
                        align-content="center"
                        justify="center"
                    >
                      <v-col
                          class="text-subtitle-1 text-center"
                          cols="12"
                      >
                        {{ $t('policy.loading') }}
                      </v-col>
                      <v-col cols="6">
                        <v-progress-linear
                            color="deep-purple accent-4"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-toolbar
                        light
                        class="elevation-0 mb-5 pa-2"
                        dense
                    >
                      <v-toolbar-title class="text-h5 text-uppercase pl-0">{{
                          $t('coc.sideMenu.reportingConcernsTitle')
                        }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                            icon
                            light
                            @click="reportingConcernsVisible = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="reporting-concerns-text" v-html="reportingConcernsText"/>
                  </template>
                </v-card>
              </v-dialog>
            </v-list>
          </v-sheet>
        </v-col>

        <v-col
            cols="12"
            md="9"
            lg="9"
        >
          <v-row
              class="fill-height"
              align-content="center"
              justify="center"
              v-if="cocLoading"
          >
            <v-col
                class="text-subtitle-1 text-center"
                cols="12"
            >
              {{ $t('coc.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row v-else class="coc-body">
            <v-col cols="12">
              <v-sheet rounded>
                <v-container class="pa-5" v-if="cocText">
                  <div v-html="cocText" id="document-text"></div>
                  <div id="signage">
                    <v-container style="height: 200px;" class="my-5" v-if="signingInProgress">
                      <v-row
                          class="fill-height"
                          align-content="center"
                          justify="center"
                      >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                          {{ $t('coc.preparingSignage') }}
                        </v-col>
                        <v-col cols="6">
                          <v-progress-linear
                              color="deep-purple accent-4"
                              indeterminate
                              rounded
                              height="6"
                          ></v-progress-linear>
                        </v-col>
                      </v-row>
                    </v-container>
                    <dokobit-iframe class="my-5" v-if="!signingInProgress && signingToken && userSigningToken"
                                    :signing-token="signingToken" :signer-token="userSigningToken"/>
                    <v-btn class="mt-5" v-if="!signingToken && !userSigningToken" @click="onSignClick"
                           color="deep-purple accent-4"
                           outlined :disabled="signingInProgress">
                      <v-icon left>
                        mdi-pencil
                      </v-icon>
                      {{ $t('coc.signDocument') }}
                    </v-btn>
                  </div>
                </v-container>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import signatureService from "@/services/signatureService";
import DokobitIframe from "@/components/codeOfConduct/DokobitIframe";
import htmlContentService from "@/services/htmlContentService";
import {ScrollOffset} from "@/router/guards/hasAccessGuard";

export default {
  name: "CodeOfConduct",
  components: {DokobitIframe},
  computed: {
    ...mapState({
      language: state => (state.auth.user ? state.auth.user.language : null),
    }),
    ...mapGetters({
      user: 'auth/user',
    }),
    isManager() {
      if (!this.user || !this.user.roles) return false;
      return this.user.roles.includes('ROLE_MANAGER');
    },
    filteredPolicies() {
      if (!this.user || !this.user.roles) return [];
      if (this.user.roles.includes('ROLE_MANAGER')) {
        return [
          {title: this.$t('coc.sideMenu.policies.business-partners'), link: 'business-partners'},
          {title: this.$t('coc.sideMenu.policies.hr'), link: 'hr'},
          {title: this.$t('coc.sideMenu.policies.safety'), link: 'safety'},
          {title: this.$t('coc.sideMenu.policies.drugs'), link: 'drugs'},
          {title: this.$t('coc.sideMenu.policies.violence'), link: 'violence'},
          {title: this.$t('coc.sideMenu.policies.human-rights'), link: 'human-rights'},
          {title: this.$t('coc.sideMenu.policies.gender-equality'), link: 'gender-equality'},
          {title: this.$t('coc.sideMenu.policies.privacy'), link: 'privacy'},
          {title: this.$t('coc.sideMenu.policies.social-responsibility'), link: 'social-responsibility'},
          {title: this.$t('coc.sideMenu.policies.environmental'), link: 'environmental'},
          {title: this.$t('coc.sideMenu.policies.responsible-fishing'), link: 'responsible-fishing'},
          {title: this.$t('coc.sideMenu.policies.prevention-of-corruption'), link: 'prevention-of-corruption'},
          {title: this.$t('coc.sideMenu.policies.gifts-and-hospitality'), link: 'gifts-and-hospitality'},
          {title: this.$t('coc.sideMenu.policies.violations-procedures'), link: 'violations-procedures'},
        ]
      }
      if (this.language === 'th') {
        return this.policies.filter((elem, i) => i !== 0);
      }
      return this.policies;
    }
  },
  data() {
    return {
      signingToken: null,
      userSigningToken: null,
      signingInProgress: false,
      cocText: null,
      cocLoading: false,
      reportingConcernsText: null,
      reportingConcernsLoading: false,
      reportingConcernsVisible: false,
      policies: [
        {title: this.$t('coc.sideMenu.policies.hr'), link: 'hr'},
        {title: this.$t('coc.sideMenu.policies.safety'), link: 'safety'},
        {title: this.$t('coc.sideMenu.policies.drugs'), link: 'drugs'},
        {title: this.$t('coc.sideMenu.policies.violence'), link: 'violence'},
        {title: this.$t('coc.sideMenu.policies.human-rights'), link: 'human-rights'},
        {title: this.$t('coc.sideMenu.policies.gender-equality'), link: 'gender-equality'},
        {title: this.$t('coc.sideMenu.policies.privacy'), link: 'privacy'},
        {title: this.$t('coc.sideMenu.policies.social-responsibility'), link: 'social-responsibility'},
        {title: this.$t('coc.sideMenu.policies.environmental'), link: 'environmental'},
        {title: this.$t('coc.sideMenu.policies.responsible-fishing'), link: 'responsible-fishing'},
        {title: this.$t('coc.sideMenu.policies.violations-procedures'), link: 'violations-procedures'},
      ],
      additionalPolicies: [
        {title: this.$t('coc.sideMenu.policies.credit-cards'), link: 'credit-cards'},
        {title: this.$t('coc.sideMenu.policies.customers'), link: 'customers'},
        {title: this.$t('coc.sideMenu.policies.job-applicants'), link: 'job-applicants'},
      ]
    }
  },
  watch: {
    language: function () {
      this.loadCoc();
    }
  },
  async created() {
    await this.loadCoc();
    if (ScrollOffset.y > 0) {
      return this.$vuetify.goTo(ScrollOffset.y + 64)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Policy') {
      ScrollOffset.y = window.pageYOffset;
      ScrollOffset.x = 0;
    } else {
      ScrollOffset.y = 0;
      ScrollOffset.x = 0;
    }
    next();
  },
  methods: {
    async loadCoc() {
      this.cocLoading = true;
      this.cocText = await htmlContentService.getCodeOfConduct();
      this.cocLoading = false;
    },
    async loadReportingConcerns() {
      if (this.reportingConcernsText) {
        this.reportingConcernsLoading = false;
        return;
      }
      this.reportingConcernsLoading = true;
      this.reportingConcernsText = await htmlContentService.getReportingConcerns();
      this.reportingConcernsLoading = false;
    },
    async onSignClick() {
      try {
        this.signingInProgress = true;
        const signingData = await signatureService.createSigning();
        this.signingToken = signingData.signingToken;
        this.userSigningToken = signingData.userSigningToken;
        this.signingInProgress = false;
      } catch (e) {
        this.signingInProgress = false;
        this.signingToken = null;
        this.userSigningToken = null;
      }
    },
  },
}
</script>

<style lang="scss">
.coc-menu {
  .v-subheader {
    color: #38466c;
  }

  .v-list-item__title {
    font-size: 1rem !important;
  }

  .v-list--dense .v-list-item .v-list-item__title {
    line-height: 1.1rem;
  }
}

.coc-body {
  h2, h3, h4 {
    padding-top: 10px;
    padding-bottom: 20px;
    color: #38466c;

    span {
      padding-right: 20px;
    }
  }

  h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h4 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .coc-lvl3 {
    color: #0e0e0e;
    font-style: italic;
    font-size: 16px;

    .num {
      padding-right: 10px;
    }
  }
}

.reporting-concerns-text {
  font-size: 16px;
  color: #000 !important;
  text-align: justify;

  li {
    margin-bottom: 20px;
  }
}

.v-dialog--custom {
  width: 100%;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog--custom {
    width: 50%;
  }
}
</style>
