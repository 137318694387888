<template>
  <v-main class="grey lighten-3">
    <v-container class="mt-5 flex-grow-1" :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col cols="12">
          <v-card
              class="mx-auto"
              max-width="500"
              tile
          >
            <v-list rounded>
              <v-subheader>{{ $t('forms.listTitle') }}</v-subheader>
              <v-list-item-group
                  color="primary"
              >
                <v-list-item @click="openGiftsForm">
                  <v-list-item-icon>
                    <v-icon>mdi-wallet-giftcard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('forms.gifts.title') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <gifts-form ref="giftsForm"/>
  </v-main>
</template>

<script>
import GiftsForm from "@/components/forms/GiftsForm";

export default {
  name: "Forms",
  components: {GiftsForm},
  data() {
    return {}
  },
  methods: {
    openGiftsForm() {
      this.$refs.giftsForm.open();
    }
  }
}
</script>