<template>
  <v-dialog
      v-model="opened"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card class="pdf-viewer-wrapping-card">
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>{{ documentTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="pdfViewZoomIn">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn icon dark @click="pdfViewZoomOut">
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
          <v-btn
              icon
              dark
              @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div id="pdfDocumentContainer">
          <v-row v-if="loadingPdf"
              class="fill-height"
              align-content="center"
              justify="center"
          >
            <v-col
                class="text-subtitle-1 text-center"
                cols="12"
            >
              {{ $t('education.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        <div id="viewer" class="pdfViewer"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist'
import {PDFViewer, EventBus} from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
import educationService from "@/services/educationService";

pdfjsLib.GlobalWorkerOptions.workerSrc =
    "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.15.349/build/pdf.worker.min.js";


const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.25;
const MAX_SCALE = 10.0;
const DEFAULT_SCALE_VALUE = "auto";

export default {
  name: "PdfViewer",
  props: {
    documentPath: String,
    documentTitle: String,
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  created() {
    let eventBus = new EventBus();
    eventBus.on("pagesinit", () => {
      this.onResize();
    });
    eventBus.on("pagesloaded", () => {
      this.loadingPdf = false;
    });
    this.eventBus = eventBus;
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  data() {
    return {
      opened: false,
      eventBus: null,
      pdfViewer: undefined,
      loadingPdf: false,
    }
  },
  methods: {
    async getPdf() {
      let loadingTask = pdfjsLib.getDocument(educationService.getDocumentPath(this.documentPath));
      let pdf = await loadingTask.promise;
      this.pdfViewer.setDocument(pdf);
    },
    open() {
      this.loadingPdf = true;
      this.opened = true;
      this.$nextTick(function() {
        let container = document.getElementById("pdfDocumentContainer");
        this.pdfViewer = new PDFViewer({
          container: container,
          eventBus: this.eventBus,
          viewer: document.getElementById("viewer"),
        });
        this.getPdf();
      });
    },
    close() {
      this.opened = false;
      this.loadingPdf = false;
    },
    onResize () {
      if(window.innerWidth < 800) {
        this.pdfViewer.currentScaleValue = 'page-width';
      } else {
        this.pdfViewer.currentScaleValue = DEFAULT_SCALE_VALUE;
      }
    },
    pdfViewZoomIn(ticks) {
      let newScale = this.pdfViewer.currentScale;
      do {
        newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);
      } while (--ticks && newScale < MAX_SCALE);
      this.pdfViewer.currentScaleValue = newScale;
    },
    pdfViewZoomOut(ticks) {
      let newScale = this.pdfViewer.currentScale;
      do {
        newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);
      } while (--ticks && newScale > MIN_SCALE);
      this.pdfViewer.currentScaleValue = newScale;
    },
  }
};
</script>

<style>
#pdfDocumentContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #eeeeee;
}
.pdfViewer {

}

.pdf-viewer-wrapping-card {
  height: 100%!important;
  width: 100%!important;
  overflow:hidden;
  border-radius: 0 !important;

}
.pdfViewer .page {
  box-sizing: initial!important;
}
</style>