import apiService from './apiService';

export default {
    async getList(page = null) {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/news`,
            {page:page},
            { withAuth: true }
        );
        return response.data;
    },

    async getNews(id = null) {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/news/${id}`,
            { withAuth: true }
        );
        return response.data;
    },
};
