<template>
  <v-app>
    <div id="app-wrapper">
      <div class="splash-container">
        <img src="/assets/sam-logo.png" alt="Samherji" class="logo mb-3"/>
        <v-progress-linear indeterminate color="primary" class="progress"/>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'splash-screen',
};
</script>

<style lang="scss" scoped>
.splash-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#app-wrapper {
  height: 100%;
}

.logo {
  max-width: 100%;
}

.progress {
  width: 300px;
  max-width: 90%;
}
</style>
