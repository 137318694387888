/* eslint-disable */
import store from '../../store/store';
import {LOCATION_AKUREYRI, LOCATION_DALVIK} from "@/api/shop";

const allowedAnonymousRoutes = ['Login'];
const notAllowedAuthenticatedRoutes = ['Login'];

/**
 * Get user roles from store
 * @returns {(null|Array)}
 */
const getUserRoles = async () => {
    if (!store.getters['auth/isSessionInitialized']) {
        await store.dispatch('auth/validateSession');
    }

    if (store.state.auth.user && store.state.auth.user.roles && store.state.auth.user.roles.length > 0) {
        return store.state.auth.user.roles;
    }

    return null;
};

/**
 * Check if user has access to current role
 * @param {Array} routeRoles - list of accepted roles
 * @param {Array} userRoles - list of user roles
 * @returns {boolean}
 */
const hasValidRole = (routeRoles, userRoles) => {
    if (!userRoles) {
        return false;
    }
    return routeRoles.some(routeRole => userRoles.includes(routeRole));
};

export const ScrollOffset = {y: 0, x: 0};

const hasAccessGuard = async function hasAccessGuard(to, from, next) {
    const userRoles = await getUserRoles();

    if(to.name === 'ProductsAkureyri') {
        await store.dispatch('products/setLocation', LOCATION_AKUREYRI);
    } else if(to.name === 'ProductsDalvik') {
        await store.dispatch('products/setLocation', LOCATION_DALVIK);
    } else {
        await store.dispatch('products/setLocation', null);
    }

    if (to.meta.isSecure && to.meta.roles && to.meta.roles.length > 0) {
        // check if route is secure and if user is authenticated with proper roles
        if (hasValidRole(to.meta.roles, userRoles)) {
            // if user have valid role continue
            next();
        } else {
            // if user doesn't have valid role redirect to login page
            next({ name: 'Login' });
        }
    }
    else if (!store.getters['auth/isAuthenticated'] && !allowedAnonymousRoutes.includes(to.name)) {
        // if user is not authenticated and route is not in allowed anonymous routes then redirect to login
        next({ name: 'Login' });
    } else if (store.getters['auth/isAuthenticated'] && notAllowedAuthenticatedRoutes.includes(to.name)) {
        // if user is authenticated and route is in not in allowed authenticated routes then redirect to home page
        await store.dispatch('auth/logout');
        next({ name: 'Login' });
    } else {
        // public route, continue
        next();
    }
};

export default hasAccessGuard;
