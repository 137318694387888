<template>
  <component :is="this.$route.meta.layout || 'div'" v-if="!loading">
    <router-view/>
  </component>
  <splash-screen v-else/>
</template>

<script>
import { mapGetters } from 'vuex';
import SplashScreen from "@/components/common/SplashScreen";

export default {
  name: 'App',
  components: {SplashScreen},
  computed: {
    loading() {
      return !this.isSessionInitialized;
    },
    ...mapGetters({
      isSessionInitialized: 'auth/isSessionInitialized',
    }),
  },
  data: () => ({
  }),
  methods: {}
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.theme--light.v-application {
  background: #fafafa !important;
}

html {
  overscroll-behavior-y: none;
}

body {
  background: #fff;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.v-application {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
</style>
