<template>
  <v-container class="d-flex py-0 my-1 align-center add-to-cart">
    <v-btn
        depressed
        color="teal lighten-5"
        class="rounded-r-0 controls"
        @click="onAddQuantity"
    >+</v-btn>
    <v-text-field
        v-model="quantity"
        readonly
        outlined
        dense
        hide-details
        single-line
        class="quantity-input mx-0"
    ></v-text-field>
    <v-btn
        depressed
        color="pink lighten-5"
        class="rounded-l-0 controls"
        @click="onReduceQuantity"
    >-</v-btn>
    <v-btn
        color="indigo darken-3"
        class="ml-2"
        text
        @click="onAddToCart"
    >
      <v-icon
          left
      >
        mdi-cart
      </v-icon>
      Add to cart
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "AddToCart",
  props: {
    maxQuantity: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      quantity: 1
    }
  },
  watch: {
    maxQuantity(newValue) {
      if(this.quantity > newValue) {
        this.quantity = newValue
      }
    }
  },
  methods: {
    onAddQuantity() {
      if(this.quantity < this.maxQuantity) {
        this.quantity++;
      }
    },
    onReduceQuantity() {
      if(this.quantity > 1) {
        this.quantity--;
      }
    },
    onAddToCart() {
      this.$emit('add-to-cart', this.quantity)
    }
  },
}
</script>

<style lang="scss">
.add-to-cart {
  .quantity-input {
    width: 44px!important;
    flex: 0 1 auto!important;
  }
  .quantity-input input {
    text-align: center!important;
    font-weight: bold!important;
  }
  .controls {
    min-width: 36px!important;
    width: 36px!important;
    font-weight: bold;
  }
}
</style>
