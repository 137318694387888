import Vue from 'vue';
import Vuex from 'vuex';
import productsModule from "@/store/modules/productsModule";
import cartModule from "@/store/modules/cartModule";
import authModule from "@/store/modules/authModule";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    modules: {
        products: productsModule,
        cart: cartModule,
        auth: authModule,
    },
    strict: debug,
    plugins: [],
});

export default store;
