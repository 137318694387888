import shop from "@/api/shop";
import {SET_LOCATION, SET_PRODUCTS, CLEAR_LOCATION} from "@/store/mutationTypes";

const initialState = {
    products: [],
    location: null,
};

const getters = {
    getProducts: state => state.products,
    location: state => state.location,
};

const actions = {
    async fetchProducts({commit}) {
        let products = await shop.getProducts();
        commit(SET_PRODUCTS, products);
    },
    async setLocation({commit}, payload) {
        commit(SET_LOCATION, payload);
    },
    async clearLocation({commit}) {
        commit(CLEAR_LOCATION);
    },
}

const mutations = {
    [SET_PRODUCTS](state, products) {
        state.products = products
    },
    [SET_LOCATION](state, location) {
        state.location = location
    },
    [CLEAR_LOCATION](state) {
        state.location = null
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
