<template>
  <layout name="BaseLayout">
    <v-main>
      <v-container>
        <v-row fluid justify="start">
          <v-col
              cols="12"
              sm="6"
              md="6"
              class="mt-4"
          >
            <v-breadcrumbs :items="breadcrumbs" large class="ma-0 pa-0">
              <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <transition name="fade" mode="out-in">
          <v-row fluid justify="center" :key="selectedLocation">
            <template v-if="products.length > 0 && selectedLocation">
              <v-col
                  v-for="(product, index) in products"
                  :key="`prod-${index}`"
                  class="col-xs-10 col-sm-8 col-md-6 col-lg-4 col-xl-3"
              >
                <products-list-item
                    :name="product.name"
                    :image="product.image"
                    :price="product.price"
                    :in-stock="product.inStock[selectedLocation]"
                    :description="product.description"
                    @add-to-cart="onAddToCart($event, product)"
                ></products-list-item>
              </v-col>
            </template>
          </v-row>
        </transition>
      </v-container>
    </v-main>
  </layout>
</template>

<script>
import ProductsListItem from "@/components/product/ProductsListItem";
import {mapActions, mapGetters} from 'vuex';
import Layout from "@/components/layout/Layout";

export default {
  name: "Products",
  components: {Layout, ProductsListItem},
  props: {
    selectedLocation: {
      type: String,
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('store.storeLocation'),
          disabled: false,
          exact: true,
          to: {name: 'SelectStore'},
        },
        {
          text: this.selectedLocation.charAt(0).toUpperCase() + this.selectedLocation.slice(1),
          disabled: true,
        }
      ];
    },
    ...mapGetters({
      products: 'products/getProducts',
    }),
  },
  data() {
    return {}
  },
  created() {
    this.fetchProducts();
    this.$emit('load-location', this.selectedLocation);
  },
  methods: {
    onAddToCart(quantity, product) {
      this.addToCart({quantity: quantity, location: this.selectedLocation, ...product})
    },
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      addToCart: 'cart/addProductToCart'
    }),
  },
}
</script>

<style>
.v-breadcrumbs__item {
  color: rgb(40, 53, 147) !important;
}

.v-breadcrumbs__item--disabled {
  color: rgb(40, 53, 147) !important;
  font-weight: bold;
}
</style>
