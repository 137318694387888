<template>
  <layout name="BaseLayout">
    <v-main class="grey lighten-3">
      <v-container class="mt-5 news" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row justify="center">
          <v-col
              cols="12"
              md="10"
          >
            <v-card
                class="mx-auto"
            >

              <v-img
                  max-height="350"
                  :src="url"
                  class="zoom-image"
                  @click="showDialog = true"
              ></v-img>

              <picture-dialog :image="url" :show="showDialog" @close="onCloseDialog"></picture-dialog>

              <v-card-title>{{ news.title }}</v-card-title>

              <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                  <div class="grey--text mb-5">
                    {{ news.publishedAt }}
                  </div>
                  <v-chip v-if="typeof news.category !== 'undefined'" pill small class="mb-5 ml-5">{{
                      news.category
                    }}
                  </v-chip>
                </v-row>

                <div class="ellipsis" v-html="news.body"></div>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10" class="mx-auto">
            <v-btn
                elevation="1"
                rounded
                color="white"
                :to="{ name: 'News' }"
            >
              <v-icon left color="indigo darken-3">mdi-chevron-left</v-icon>
              {{ $t('news.backToList') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </layout>
</template>

<script>
import newsService from "@/services/newsService";
import PictureDialog from "@/components/news/PictureDialog";
import Layout from "@/components/layout/Layout";

export default {
  name: "NewsSingle",
  components: {Layout, PictureDialog},
  props: {
    id: {
      type: [Number, String],
    },
  },
  computed: {
    url() {
      if (this.news.coverImage) {
        return this.imgBaseUrl + this.news.coverImage;
      }
      return null;
    }
  },
  data() {
    return {
      news: {},
      showDialog: false,
      imgBaseUrl: `${process.env.VUE_APP_API_URL}/images/`,
    }
  },
  async created() {
    this.news = await newsService.getNews(this.id);
  },
  methods: {
    onCloseDialog() {
      this.showDialog = false;
    }
  }
}
</script>

<style>
.news img {
  max-width: 100% !important;
}

.fr-fir {
  float: right;
  max-width: calc(100% - 5px);
  margin: 5px 0 5px 5px;
}

.fr-img-caption {
  border: 1px solid #cecece;
  padding: 4px 8px;
  border-radius: 5px;
  margin: 0 5px 0 5px;
}

.fr-img-caption .fr-inner {
  font-weight: bold;
  font-style: italic;
}

.zoom-image {
  cursor: zoom-in;
}
</style>
