<template>
  <v-dialog
      v-model="opened"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card class="forms-gifts-wrapping-card" :loading="requestPending" :loader-height="5">
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>{{ $t('forms.gifts.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container class="pt-10">
          <v-form ref="form" v-model="valid" :disabled="requestPending || requestSuccess">
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="title"
                    :rules="[rules.required]"
                    :label="$t('forms.gifts.form.title')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  xl="4"
              >
                <v-text-field
                    v-model="from"
                    :rules="[rules.required]"
                    :label="$t('forms.gifts.form.from')"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  xl="4"
              >
                <v-text-field
                    v-model="to"
                    :rules="[rules.required]"
                    :label="$t('forms.gifts.form.to')"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" xl="3">
                <v-text-field
                    v-model.number="value"
                    :rules="[rules.requiredAmount]"
                    :label="$t('forms.gifts.form.value')"
                    type="number"
                    :prefix="valuePrefix"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" xl="1">
                <v-select
                    :items="[{text: 'ISK', value: 'ISK'}, {text: 'EUR', value: 'EUR'}]"
                    v-model="currency"
                    :label="$t('forms.gifts.form.currency')"
                    outlined
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    :label="$t('forms.gifts.form.comments')"
                    v-model="comments"
                    outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-space-around">

        <v-dialog
            v-model="confirmationDialog"
            persistent
            max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-5 mb-5 px-5" v-bind="attrs" v-on="on" color="deep-purple accent-4" outlined :disabled="!valid || requestPending || requestSuccess"
                   large>
              <v-icon left>
                mdi-pencil
              </v-icon>
              {{ $t('forms.submitLabel') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              {{ $t('forms.confirmationTitle') }}
            </v-card-title>
            <v-card-text class="font-weight-bold">{{ $t('forms.confirmationText')}}</v-card-text>
            <v-card-actions class="justify-space-around">
              <v-btn
                  color="green darken-1"
                  text
                  @click="onSubmitClick"
              >
                {{ $t('forms.proceed') }}
              </v-btn>
              <v-btn
                  color="pink accent-3"
                  text
                  @click="confirmationDialog = false"
              >
                {{ $t('forms.cancel') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn class="mt-5 mb-5 px-5" @click="close" color="pink accent-3" outlined large>
          {{ $t('forms.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
        v-model="requestSuccess"
        persistent
        hide-overlay
        width="150"
    >
      <v-card
          color="green accent-2"
          class="success-dialog"
      >
        <v-card-text class="text-center pa-5">
          <v-row>
            <v-col cols="12">
              <v-icon
                  color="white"
                  class="success-icon"
              >
                mdi-check-bold
              </v-icon>
            </v-col>
            <v-col cols="12"><strong>Form saved</strong></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import formsService from '@/services/formsService';

export default {
  name: "GiftsForm",
  data() {
    return {
      opened: false,
      valid: true,
      confirmationDialog: false,
      requestPending: false,
      requestSuccess: false,
      title: '',
      from: '',
      to: '',
      value: 0.00,
      currency: 'ISK',
      comments: '',
      rules: {
        required: value => !!value || this.$t('error.validation.required'),
        requiredAmount: value => value > 0 || this.$t('error.validation.required'),
      },
    }
  },
  computed: {
    valuePrefix() {
      return this.currency === 'ISK' ? 'kr' : '€'
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.$nextTick(() => {
        this.requestSuccess = false;
        this.requestPending = false;
        this.clearForm();
        this.$refs.form.resetValidation();
      });
    },
    close() {
      this.confirmationDialog = false;
      if (!this.requestPending) {
        this.clearForm();
        this.requestSuccess = false;
        this.requestPending = false;
        this.opened = false;
      }
    },
    onSubmitClick() {
      if (this.valid && !this.requestPending) {
        this.confirmationDialog = false;
        this.requestPending = true;
        formsService.addGiftEntry({
          title: this.title,
          from: this.from,
          to: this.to,
          value: this.value,
          currency: this.currency,
          comments: this.comments,
        }).then(() => {
          this.requestPending = false;
          this.requestSuccess = true;
          setTimeout(() => {
            this.requestSuccess = false;
            this.close();
          }, 2000);
        })
            .catch((error) => {
              console.log(error);
              this.requestPending = false;
              this.requestSuccess = false;
            })
      }
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.currency = 'ISK';
    },
  }
};
</script>

<style lang="scss">
.forms-gifts-wrapping-card {
  .v-toolbar {
    flex: 0 0 auto;
  }

  .v-card__actions {
    background: #efeffd;
  }
}

.success-dialog {
  .success-icon {
    font-size: 72px;
  }

  .col {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>