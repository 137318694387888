const companiesWithDepartments = [
    {
        "name": "Ice Fresh Seafood",
        "code" : 5000,
        "departments" : [
            { "code" : 5010, "name" : "Akureyri" },
            { "code" : 5020, "name" : "Reykjavík" },
            { "code" : 5030, "name" : "Pólland" },
            { "code" : 5040, "name" : "Eldi" },
            { "code" : 5050, "name" : "Uppsjávardeild" },
            { "code" : 5060, "name" : "Seagold" },
            { "code" : 5080, "name" : "Hausar" },
            { "code" : 5090, "name" : "Stjórnun" }
        ]
    },
    {
        "name" : "Samherji",
        "code" : 1100,
        "departments" : [
            { "code" : 1100, "name" : "Samherji" },
            { "code" : 1111, "name" : "Fjármálasvið" },
            { "code" : 1112, "name" : "Mannauðssvið" },
            { "code" : 1113, "name" : "Upplýsingatæknisvið" },
            { "code" : 1114, "name" : "Flutningasvið" }
        ]
    },
    {
        "name" : "Samherji Fiskeldi ehf",
        "code" : 4400,
        "departments" : [
            { "code" : 4140, "name" : "Núpar" },
            { "code" : 4310, "name" : "Núpsmýri" },
            { "code" : 4320, "name" : "Seiðaeldi Núpsmýri" },
            { "code" : 4370, "name" : "Sigtún Klakstöð" },
            { "code" : 4390, "name" : "Vinnsla Núpsmýri" },
            { "code" : 4410, "name" : "Staður" },
            { "code" : 4430, "name" : "Seiðahús Staður" },
            { "code" : 4440, "name" : "Vinnsla Sandgerði" },
            { "code" : 4470, "name" : "Vatnsleysa" },
            { "code" : 4914, "name" : "Garður" },
            { "code" : 9840, "name" : "Samh.Fiske. Stj.k" }
        ]
    },
    {
        "name" : "Samherji Ísland ehf.",
        "code" : 1000,
        "departments" : [
            { "code" : 1210, "name" : "Björgvin EA-311" },
            { "code" : 1230, "name" : "Snæfell EA-310" },
            { "code" : 1360, "name" : "Björgúlfur EA-312" },
            { "code" : 1370, "name" : "Oddeyrin EA-210" },
            { "code" : 1380, "name" : "Björg EA-7" },
            { "code" : 1430, "name" : "Vilhelm Þorst.EA-11" },
            { "code" : 1550, "name" : "Margret EA-710" },
            { "code" : 3230, "name" : "Hausaþurrkun" },
            { "code" : 3250, "name" : "Fiskvinnsla Dalvík" },
            { "code" : 7110, "name" : "Verkstæði" },
            { "code" : 7150, "name" : "Frystiklefi - Strýtu" },
            { "code" : 9100, "name" : "Aðalskrifstofa" },
            { "code" : 9300, "name" : "Landvinnslusvið" },
            { "code" : 9700, "name" : "Útgerðarsvið" }
        ]
    },
    {
        "name" : "Útgerðarfélag Akureyringa",
        "code" : 1010,
        "departments" : [
            { "code" : 1011, "name" : "Landvinnsla ÚA" },
            { "code" : 1012, "name" : "Hausaþurrkun ÚA" },
            { "code" : 1017, "name" : "Kaldbakur EA-1" },
            { "code" : 1018, "name" : "Pökkunarstöð ÚA" },
            { "code" : 1022, "name" : "Harðbakur" }
        ]
    }
];

export default companiesWithDepartments;