import apiService from './apiService';

export default {
    authIntervalId: null,

    async getCodeOfConduct() {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/html/code-of-conduct`,
        );
        return response.data;
    },

    async getPolicy(name) {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/html/code-of-conduct/policies/${name}`,
        );
        return response.data;
    },

    async getReportingConcerns() {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/html/code-of-conduct/reporting-concerns`,
        );
        return response.data;
    },
};
