import apiService from './apiService';

export default {
    async getStats() {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/dashboard/stats`,
            { withAuth: true }
        );
        return response.data;
    },
    async getSignageTable(filters) {
        const response = await apiService.get(
            `${process.env.VUE_APP_API_URL}/api/dashboard/signage-table`,
            { withAuth: true, signed: filters.signed, type: filters.type, companyCode: filters.companyCode, departmentCode: filters.departmentCode }
        );
        return response.data;
    },
};
