<template>
  <v-navigation-drawer
      :value="show"
      temporary
      app
      @input="onInput"
  >
    <v-list dense v-if="fullName">
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon color="indigo darken-3" class="pt-2">
            mdi-logout
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('login.logout') }}<br/><span style="font-weight: bold">{{ fullName }}</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>

    <v-list dense>
      <!--<v-list-item
          link
          :to="{ name: 'News' }"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-newspaper-variant-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('news.linkTitle') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->
      <v-list-item
          link
          :to="{ name: 'CoC' }"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('coc.linkTitle') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          link
          :to="{ name: 'Education' }"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-school-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('education.linkTitle') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          link
          :to="{ name: 'Forms' }"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-form-textbox</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('forms.linkTitle') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--
      <v-list-item
          link
          :to="{ name: 'SelectStore' }"
      >
        <v-list-item-icon>
          <v-icon color="primary">mdi-cart</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t('store.linkTitle') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "SideNavigation",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState({
      fullName: state => (state.auth.user ? state.auth.user.fullName : null),
    }),
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>

<style scoped>

</style>
