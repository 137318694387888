import jwtDecode from 'jwt-decode';
import apiService from './apiService';
import errorService from './errorService';
import store from '../store/store';

const SHARED_AUTH_URL = `${process.env.VUE_APP_API_URL}/shared/auth`;

export default {
    SHARED_AUTH_URL,
    authIntervalId: null,
    refreshTokenTimer: -1,

    async authenticateAsAdmin() {
        try {
            return apiService.post(
                `${process.env.VUE_APP_API_URL}/shared/auth`,
                {token: window.localStorage.getItem('accessToken')},
                {withAuth: false},
            );
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async authenticate(phoneNumber) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/auth`,
                { phone_number: phoneNumber },
                { withAuth: false },
            );
            const authAttemptToken = response.data.token;
            this.authIntervalId = setInterval(() => {
                this.checkAuthStatus(authAttemptToken);
            }, 3000);
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async setLanguage(languageId) {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/users/language`,
                { language: languageId }
            );
            return response.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async checkAuthenticationStatus(authAttemptToken) {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/auth/status?token=${authAttemptToken}`,
                null,
                { withAuth: false },
            );
            if (response.data.status === 'waiting') {
                return;
            }
            if (response.data.status === 'ok') {
                window.localStorage.setItem('accessToken', response.data.token);
                window.localStorage.setItem('refreshToken', response.data.refreshToken);
                return response.data;
            }
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserLight() {
        try {
            const response = await apiService.get(
                `${process.env.VUE_APP_API_URL}/api/users/myself`,
            );
            return response.data;
        } catch (e) {
            // const formattedError = errorService.getFormattedError(e);
            throw new Error(e.response);
        }
    },

    logout() {
        this.cleanStorage();
        this.cancelScheduledRefreshToken();
    },

    cleanStorage() {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
    },

    async validateToken(token) {
        try {
            let tokenData = this.parseToken(token);

            if (tokenData) {
                const { exp } = tokenData;
                const currentTime = new Date().getTime();

                if (exp > currentTime) {
                    this.scheduleRefreshToken(exp);
                } else {
                    let newToken = await store.dispatch('auth/refreshToken');
                    if(newToken) {
                        return this.parseToken(token);
                    }
                    return null;
                }

                return tokenData;
            }

            return null;
        } catch (e) {
            return null;
        }
    },

    parseToken(token) {
        try {
            const decoded = jwtDecode(token);
            const {
                exp, roles, username
            } = decoded;
            const expTime = exp * 1000; // convert to milliseconds
            return {
                exp: expTime,
                username,
                roles,
            };
        } catch (e) {
            return null;
        }
    },

    cancelScheduledRefreshToken() {
        if (this.refreshTokenTimer) {
            window.clearTimeout(this.refreshTokenTimer);
        }
    },

    scheduleRefreshToken(exp) {
        const currentTime = new Date().getTime();

        // cancel existing refresh timer
        this.cancelScheduledRefreshToken();

        if (!exp || exp <= currentTime) {
            return;
        }

        // refresh access token 3min before it expires
        const refreshTime = exp - currentTime - 180000;

        this.refreshTokenTimer = window.setTimeout(() => {
            store.dispatch('auth/refreshToken');
        }, refreshTime);
    },
};
