import apiService from './apiService';

export default {
    async addGiftEntry(payload) {
        const response = await apiService.post(
            `${process.env.VUE_APP_API_URL}/api/forms/gifts`,
            payload,
            { withAuth: true }
        ).catch(err => {
            throw err.message;
        })
        return response.data;
    },
};
