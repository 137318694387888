import { i18n } from '../../i18n/i18n';
import router from '../../router';
import authService from '../../services/authService';
import apiService from '../../services/apiService';
import {
    AUTHENTICATE_REQUEST,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILURE,
    FETCH_USER_LIGHT_REQUEST,
    FETCH_USER_LIGHT_SUCCESS,
    FETCH_USER_LIGHT_FAILURE,
    SET_LANGUAGE_SUCCESS,
    INITIALIZE_AUTH,
    LOGOUT,
} from '../mutationTypes';

const initialState = {
    initialized: false,
    exp: null,
    user: null,
    loading: {
        authenticate: false,
        fetchUserLight: false,
    }
};

const getters = {
    isSessionInitialized: (state) => state.initialized,
    user: (state) => state.user,
    isAuthenticated: (state) => !!state.user,
};

const actions = {
    async validateSession({ commit }) {
        try {
            const tokenData = await authService.validateToken(window.localStorage.getItem('accessToken'));
            if (tokenData) {
                const {
                    exp, username, roles,
                } = tokenData;

                const userLightResponse = await authService.fetchUserLight();
                // const newLocale = getLocaleFromLanguage(userLightResponse.language);
                // await loadLanguageAsync(newLocale);

                commit(AUTHENTICATE_SUCCESS, {
                    exp,
                    username,
                    roles,
                });
                commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            } else {
                commit(LOGOUT);
            }
        } catch (e) {
            commit(LOGOUT);
        }

        commit(INITIALIZE_AUTH);
    },

    async authenticate({ commit }, payload) {
        try {
            commit(AUTHENTICATE_REQUEST);
            const { token, fullName, country, phoneNumber, language, redirect } = payload;
            const tokenData = authService.parseToken(token);

            if (!tokenData) {
                authService.logout();
                throw new Error('error.default');
            }

            const {
                exp, id, username: tokenUsername, roles,
            } = tokenData;

            //const userLightResponse = await authService.fetchUserLight();
            //authService.checkIfUserAllowed(userLightResponse);
            //const newLocale = getLocaleFromLanguage(userLightResponse.language);
            //await loadLanguageAsync(newLocale);

            //dispatch('cleanStore');

            commit(AUTHENTICATE_SUCCESS, {
                exp,
                id,
                username: tokenUsername,
                fullName,
                country,
                phoneNumber,
                roles,
                language,
            });

            if (redirect) {
                router.push({ name: redirect });
            } else {
                router.push({ path: '/' });
            }
        } catch (e) {
            commit(AUTHENTICATE_FAILURE);
            throw e;
        }
    },

    async refreshToken() {
        try {
            const response = await apiService.post(
                `${process.env.VUE_APP_API_URL}/api/token/refresh`,
                { refresh_token: window.localStorage.getItem('refreshToken') }
            );
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem('accessToken', token);
            window.localStorage.setItem('refreshToken', refresh_token);
            return response.data.token;
        } catch (e) {
            //const formattedError = errorService.getFormattedError(e);
            if (e.statusCode === 401) {
                window.localStorage.removeItem('refreshToken');
            }
            //throw new Error(formattedError.message);
        }
    },

    mockLogout({ commit }) {
        commit(LOGOUT);
        router.push({ name: 'Login' });
    },

    logout({ commit, dispatch }, payload = {}) {
        const { redirectUrl, noRedirect } = payload;
        authService.logout();
        commit(LOGOUT);
        dispatch('cleanStore');
        dispatch('cleanLocalStorage');
        if (noRedirect !== true) router.push({ name: 'Login' });

        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    },

    async setLanguage({ commit }, payload = {}) {
        const { id } = payload;
        await authService.setLanguage(id);
        commit(SET_LANGUAGE_SUCCESS, payload);
    },

    cleanStore(/*{ dispatch }*/) {
        // TODO ...
    },

    cleanLocalStorage() {
        const appKeys = ['locale', 'accessToken', 'refreshToken', 'cart'];
        Object.keys(appKeys).forEach((key) => window.localStorage.removeItem(key));
    },
};

const mutations = {
    // -----------------------------------------
    // INITIALIZE
    // -----------------------------------------
    [INITIALIZE_AUTH](state) {
        state.initialized = true;
    },

    // -----------------------------------------
    // AUTHENTICATE
    // -----------------------------------------
    [AUTHENTICATE_REQUEST](state) {
        state.loading.authenticate = true;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },
    [AUTHENTICATE_SUCCESS](state, payload) {
        const {
            exp, id, fullName, country, username, roles, phoneNumber, language
        } = payload;
        state.loading.authenticate = false;
        state.exp = exp;
        state.user = {
            id, username, fullName, country, roles, phoneNumber, language
        };
        if(language) {
            i18n.locale = language;
        }
    },
    [AUTHENTICATE_FAILURE](state) {
        state.loading.authenticate = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    [FETCH_USER_LIGHT_REQUEST](state) {
        state.loading.fetchUserLight = true;
    },
    [FETCH_USER_LIGHT_SUCCESS](state, payload) {
        const {
            id, username, fullName, email, language, createdAt,
        } = payload;
        state.loading.fetchUserLight = false;
        state.user = {
            ...state.user,
            id,
            username,
            fullName,
            email,
            language,
            createdAt,
        };
        if(language) {
            i18n.locale = language;
        }
    },
    [FETCH_USER_LIGHT_FAILURE](state) {
        state.loading.fetchUserLight = false;
    },

    // -----------------------------------------
    // LOGOUT
    // -----------------------------------------
    [LOGOUT](state) {
        state.user = null;
    },

    [SET_LANGUAGE_SUCCESS](state, payload) {
        state.user.language = payload.id;
        i18n.locale = payload.id;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
