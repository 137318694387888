import { i18n } from '@/i18n/i18n';

export default {
    getFormattedError(error, defaultMessage = 'error.default') {
        const { message } = error;
        let errorMessage = message || i18n.t(defaultMessage);
        let statusCode = 500;

        if (error.response) {
            const { status, data } = error.response;

            // axios error
            statusCode = status;

            if (status === 404) {
                errorMessage = i18n.t('error.notFound');
            } else if (data && data.fields && Object.keys(data.fields).length > 0 && typeof data.fields[Object.keys(data.fields)[0]] === 'string') {
                if (data.code) {
                    statusCode = data.code;
                }
                // message is already coming translated from backed in user selected language
                errorMessage = data.fields[Object.keys(data.fields)[0]];
            } else if (data && data.errorCode) {
                if (data.code) {
                    statusCode = data.code;
                }
                errorMessage = i18n.t(`error.${data.errorCode}`);
            } else if (data && data.error && data.error.errorCode) {
                if (data.error.code) {
                    statusCode = data.error.code;
                }

                errorMessage = i18n.t(`error.${data.error.errorCode}`);
            } else if (data && data.message) {
                if (data.code) {
                    statusCode = data.code;
                }

                errorMessage = i18n.t(`error.${data.message}`);
            } else if (data && data.error && data.error.message) {
                if (data.error.code) {
                    statusCode = data.error.code;
                }

                errorMessage = i18n.t(`error.${data.error.message}`);
            }
        } else if (error.request) {
            // axios network error
            statusCode = 503;
            errorMessage = i18n.t('error.network');
        }

        return { message: errorMessage, statusCode };
    },
};
