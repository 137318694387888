<template>
  <layout name="BaseLayout">
    <v-main class="grey lighten-3">
      <template v-if="error">
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-alert type="error" outlined text v-if="error" class="col-3">{{ $t('news.error') }}</v-alert>
          </v-row>
        </v-container>
      </template>
      <v-container v-else class="mt-5" :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row>
          <v-col
              cols="12"
              md="2"
          >
            <v-sheet
                rounded="lg"
                :class="{ sticky: $vuetify.breakpoint.mdAndUp }"
            >
              <v-list
                  nav
                  dense
                  class="rounded"
                  color="white"
              >
                <v-subheader class="text-h6 ">{{ $t('news.categories') }}</v-subheader>
                <v-list-item link>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t('news.companyRelated')"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col
              cols="12"
              md="10"
          >
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
                v-if="newsLoading"
            >
              <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
              >
                {{ $t('news.loading') }}
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col md="6" v-for="(post) in news" :key="`col-news-${post.id}`">
                <news-list-item
                    :id="post.id"
                    :title="post.title"
                    :image="post.coverImage"
                    :publishedAt="post.publishedAt"
                    :intro="post.preview"></news-list-item>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!moreNewsLoading && !newsLoading">
          <v-col cols="12" sm="4" class="offset-md-2 mb-5">
            <v-btn :disabled="!loadMoreAvailable" color="primary" block @click="onLoadMore">{{
                $t('news.loadMore')
              }}
            </v-btn>
          </v-col>
        </v-row>
        <v-container v-if="moreNewsLoading">
          <v-row
              class="fill-height"
              align-content="center"
              justify="center"
          >
            <v-col
                class="text-subtitle-1 text-center offset-md-2"
                cols="12"
            >
              {{ $t('news.loadingMore') }}
            </v-col>
            <v-col cols="6" class="offset-md-2 mb-5">
              <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
  </layout>
</template>

<script>
// @ is an alias to /src
import newsService from "@/services/newsService";

import NewsListItem from "@/components/news/NewsListItem";
import layout from "@/components/layout/Layout";

export default {
  name: 'News',
  components: {layout, NewsListItem},
  data() {
    return {
      newsLoading: false,
      moreNewsLoading: false,
      error: false,
      news: [],
      page: 0,
      loadMoreAvailable: true,
    }
  },
  async created() {
    try {
      this.newsLoading = true;
      this.news = await newsService.getList(this.page);
    } catch (e) {
      this.error = true;
      this.newsLoading = false;
      this.loadMoreAvailable = false;
    }
    if (this.news.length === 0) {
      this.loadMoreAvailable = false;
    }
    this.newsLoading = false;
  },
  methods: {
    onLoadMore() {
      this.loadMoreNews();
    },
    async loadMoreNews() {
      this.page++;
      this.moreNewsLoading = true;
      try {
        let newSet = await newsService.getList(this.page);
        if (newSet.length === 0) {
          this.loadMoreAvailable = false;
        }
        this.moreNewsLoading = false;
        this.news = this.news.concat(newSet);
      } catch (e) {
        this.moreNewsLoading = false;
        this.loadMoreAvailable = false;
        this.page--;
      }
    }
  },
}
</script>

<style>
/*.theme--light.v-chip.long:hover::before {*/
/*  opacity: 0;*/
/*}*/

/*.theme--light.v-chip.long:hover {*/
/*  color: #000!important;*/
/*  cursor: pointer;*/
/*}*/

.sticky {
  position: sticky;
  top: 96px;
}
</style>
