<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="onHamburgerClick" class="hidden-md-and-up"></v-app-bar-nav-icon>
    <v-img src="/assets/sam-logo.png" contain max-height="100%" style="flex-grow: 0"/>
    <v-tabs
        v-model="menuTab"
        ref="tabsComponent"
        align-with-title
        class="hidden-sm-and-down"
        color="indigo darken-3"
    >
      <v-tabs-slider color="indigo darken-3"></v-tabs-slider>
      <!--<v-tab :to="{name: 'News'}" @click="onNewsTabClick">{{ $t('news.linkTitle') }}</v-tab>-->
      <!--<v-tab>{{ $t('store.linkTitle') }}</v-tab>-->
      <v-tab v-if="hasDashboard" :to="{name: 'Dashboard'}">{{ $t('dashboard.linkTitle') }}</v-tab>
      <v-tab :to="{name: 'CoC'}">{{ $t('coc.linkTitle') }}</v-tab>
      <!--<v-tab :to="{name: 'Education'}">{{ $t('education.linkTitle') }}</v-tab>-->
      <!--<v-tab :to="{name: 'Forms'}">{{ $t('forms.linkTitle') }}</v-tab>-->
    </v-tabs>
    <v-spacer/>
    <!--<v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-top="-10"
        offset-y
        transition="slide-x-transition"
        v-if="storeLocation !== null"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover
            v-slot="{ hover }"
        >
          <v-chip
              class="ma-2 long py-4 rounded-1 elevation-1"
              color="indigo darken-3"
              :style="{ 'background-color': (hover ? 'grey lighten-3!important' : 'white!important')}"
              pill
              v-bind="attrs"
              v-on="cartItems[storeLocation].length > 0 ? on : null"
          >
            <v-icon color="primary">
              mdi-basket
            </v-icon>
            <span class="ml-2">
              <template v-if="cartItems[storeLocation].length > 0">
                <strong>{{ cartItems[storeLocation].length }}</strong> <span class="hidden-sm-and-down">{{$t(cartItems[storeLocation].length > 1 ? 'store.qtyPluralInBasket' : 'store.qtySingularInBasket') }}</span>
              </template>
              <template v-else>
                <strong class="hidden-md-and-up">0</strong><span class="hidden-sm-and-down">{{ $t('store.basketEmpty') }}</span>
              </template>
          </span>
          </v-chip>
        </v-hover>
      </template>-

      <v-card>
        <v-list>
          <template v-for="(item, index) in locationItems">
            <v-list-item :key="`item-${index}`">
              <v-list-item-action>
                <v-avatar dark size="24" color="indigo darken-3"><span class="white--text">{{ item.quantity }}</span></v-avatar>
              </v-list-item-action>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item>

            <v-divider :key="`divider-${index}`"></v-divider>
          </template>
        </v-list>

        <v-card-actions>
          <v-btn
              text
              @click="onEmptyCart"
          >
            <v-icon left>mdi-delete</v-icon>
            {{ $t('store.emptyCart') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
          >
            {{ $t('store.checkout') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>-->

    <form v-if="isUserManager" method="post" :action="sharedAuthUrl" target="_blank">
      <input type="hidden" name="token" :value="token" />
      <v-btn type="submit" small rounded class="elevation-1 mr-2">
      <v-icon left dark>mdi-account-group</v-icon> Notendastjórnun</v-btn>
    </form>

    <v-menu v-model="languageMenu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="text-capitalize" v-bind="attrs" v-on="on" text>
          <v-icon left>mdi-translate</v-icon>
          <template v-if="$vuetify.breakpoint.mdAndUp">{{ activeLanguageName }}</template>
          <v-icon small right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(lang, index) in languages" :key="index" @click="handleLanguageMenuClick(lang)">
          <v-list-item-title>{{ lang.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <template v-if="fullName && $vuetify.breakpoint.mdAndUp">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-hover
              v-slot="{ hover }"
          >
            <v-chip
                class="ma-2 long py-4 rounded-1 elevation-1"
                color="indigo darken-3"
                :style="{ 'background-color': (hover ? 'grey lighten-3!important' : 'white!important')}"
                @click.stop="onLogoutClick"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon left color="indigo darken-3">
                mdi-logout
              </v-icon>
              {{ fullName }}
            </v-chip>
          </v-hover>
        </template>
        <span>{{ $t('login.logout') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-hover
          >
            <v-btn
                class="ma-2 py-4 elevation-1 hidden-md-and-up"
                fab
                small
                color="primary"
                outlined
            >
            <v-icon
                @click.stop="onLogoutClick"
                v-bind="attrs"
                v-on="on"
            >
                mdi-logout
              </v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span>{{ $t('login.logout') }}</span>
      </v-tooltip>
    </template>
<!--    <template v-if="menuTab === 1" v-slot:extension>-->
<!--        <v-tabs centered v-model="locationTab" optional>-->
<!--          <v-tab :to="{name: 'ProductsAkureyri'}">Akureyri</v-tab>-->
<!--          <v-tab :to="{name: 'ProductsDalvik'}">Dalvik</v-tab>-->
<!--        </v-tabs>-->
<!--    </template>-->
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import authService from "@/services/authService";

export default {
  name: "AppBar",
  computed: {
    ...mapGetters({
      user: 'auth/user',
      cartItems: 'cart/getCart',
      storeLocation: 'products/location',
    }),
    ...mapState({
      fullName: state => (state.auth.user ? state.auth.user.fullName : null),
    }),
    hasDashboard() {
      if(!this.user || !this.user.roles) return false;
      return this.user.roles.includes('ROLE_DASHBOARD');
    },
    isManager() {
      if(!this.user || !this.user.roles) return false;
      return this.user.roles.includes('ROLE_MANAGER');
    },
    isUserManager() {
      if(!this.user || !this.user.roles) return false;
      return this.user.roles.includes('ROLE_USER_MANAGER');
    },
    locationItems() {
      if(!this.storeLocation) return [];
      return this.cartItems[this.storeLocation];
    },
    activeLanguageName() {
      if(!this.user || !this.user.language) return null;
      let currentLang = this.langs.find(lang => lang.id === this.user.language);
      return currentLang ? currentLang.title : null;
    },
    languages() {
      if(!this.user || !this.user.roles) return null;
      if(this.user.roles.includes('ROLE_MANAGER')) {
        return [
          {
            title: 'Íslenska',
            id: 'is',
          },
          {
            title: 'English',
            id: 'en',
          },
        ]
      }
      return [
        {
          title: 'Íslenska',
          id: 'is',
        },
        {
          title: 'English',
          id: 'en',
        },
        {
          title: 'ภาษาไทย',
          id: 'th',
        },
        {
          title: 'Polski',
          id: 'pl',
        },
      ]
    },

  },
  data() {
    return {
      token: window.localStorage.getItem('accessToken'),
      sharedAuthUrl: authService.SHARED_AUTH_URL,
      menuTab: this.$route.path,
      locationTab: -1,
      menu: false,
      languageMenu: false,
      langs: [
        {
          title: 'Íslenska',
          id: 'is',
        },
        {
          title: 'English',
          id: 'en',
        },
        {
          title: 'ภาษาไทย',
          id: 'th',
        },
        {
          title: 'Polski',
          id: 'pl',
        },
      ]
    }
  },
  watch: {
    menuTab(oldVal, newVal) {
      console.log(oldVal + ' VS ' + newVal);
    }
  },
  created() {
    if(this.$router.currentRoute.name === 'ProductsAkureyri' || this.$router.currentRoute.name === 'ProductsDalvik') {
      this.menuTab = 1;
    }
  },
  methods: {
    async onAdminClick() {
      authService.authenticateAsAdmin().then(() => {
        window.open('https://127.0.0.1:8000/admin');
      })
    },
    onEmptyCart() {
      this.emptyCart(this.storeLocation);
      this.menu = false;
    },
    onHamburgerClick() {
      this.$emit('toggle-side-navigation');
    },
    onNewsTabClick() {
      this.locationTab = -1;
      this.menuTab = 0;
    },
    onLogoutClick() {
      this.logout();
    },
    async handleLanguageMenuClick(lang) {
      await this.setLanguage(lang);
      this.$refs.tabsComponent.onResize();
    },
    ...mapActions({
      logout: 'auth/logout',
      setLanguage: 'auth/setLanguage',
      emptyCart: 'cart/emptyCart'
    })
  },
}
</script>

<style>
.v-chip.long {
  overflow: visible;
}

.v-toolbar__extension {
  background-color: #f1f1f1!important;
}
</style>
