<template>
  <v-dialog
      :value="show" scrollable @click:outside="closeDialog">
    <v-card>
      <v-toolbar dense color="elevation-0">
        <v-spacer></v-spacer>
        <v-btn icon color="black" @click.native="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row no-gutters style="overflow: scroll;" align="center" justify="center">
            <img
                :src="image"
                width="auto"
            />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PictureDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close")
    }
  },
}
</script>

<style scoped>

</style>
