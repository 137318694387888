<template>
  <v-col
      cols="12"
      md="3"
      xl="2"
  >
    <v-sheet
        rounded="lg"
        :class="{ sticky: sticky, 'policy-menu' : true }"
    >
      <v-list
          nav
          class="rounded"
          color="white"
      >
        <v-list-item link :to="{name: 'CoC'}">
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('policy.backToCoc')" class="back-to-coc"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-col>
</template>

<script>
export default {
  name: "BackToButton",
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
