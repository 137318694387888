//import apiService from '@/services/apiService';

export const LOCATION_DALVIK = 'dalvik';
export const LOCATION_AKUREYRI = 'akureyri';

const productsService = {
    async getProducts() {
        //try {
            // const response = await apiService.get(
            //     `${process.env.VUE_APP_API_URL}/api/products`
            // );
            // return response.data;
            return [
                {
                    id: 1,
                    name: 'Frozen COD fillet, 9 kg\'s BOX',
                    image: '/assets/product-2.jpg',
                    price: 15000,
                    inStock: {
                        dalvik: 27,
                        akureyri: 13,
                    },
                    description: 'Frozen at sea. First-class product, premium quality.'
                },
                {
                    id: 2,
                    name: 'Frozen COD fillet, 11 kg\'s BOX',
                    image: '/assets/product-1.jpg',
                    price: 18300,
                    inStock: {
                        dalvik: 27,
                        akureyri: 13,
                    },
                    description: 'Frozen at sea. First-class product, premium quality.'
                }
            ];
        // } catch (e) {
        //     //const formattedError = errorService.getFormattedError(e);
        //     throw new Error(e.message);
        // }
    },
};

export default productsService;
