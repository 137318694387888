import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '@/i18n/i18n';
import Login from '../views/Login.vue'
import News from '../views/News.vue'
import NewsSingle from "@/views/NewsSingle";
import Dashboard from '../views/Dashboard.vue'
import Education from "@/views/Education";
import Forms from "@/views/Forms";
import SelectStore from '../views/SelectStore'
import Products from '../views/Products'
import CodeOfConduct from "@/views/CodeOfConduct";
import Policy from "@/views/Policy";
import {LOCATION_AKUREYRI, LOCATION_DALVIK} from "@/api/shop";
import hasAccessGuard from "@/router/guards/hasAccessGuard";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import LoginLayout from "@/components/layout/LoginLayout.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: LoginLayout,
    }
  },
  {
    path: '/',
    redirect: {
      name: 'CoC'
    }
  },
  {
    path: '/code-of-conduct',
    name: 'CoC',
    component: CodeOfConduct,
    props: true,
    meta: {
      layout: BaseLayout,
      title: 'coc.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    meta: {
      layout: BaseLayout,
      title: 'news.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/news/:id',
    name: 'SinglePost',
    component: NewsSingle,
    props: true,
    meta: {
      layout: BaseLayout,
      title: 'news.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: BaseLayout,
      title: 'dashboard.pageTitle',
      isSecure: true,
      roles: ['ROLE_DASHBOARD'],
    },
  },
  {
    path: '/code-of-conduct/policies/:name',
    name: 'Policy',
    component: Policy,
    props: true,
    meta: {
      layout: BaseLayout,
      title: 'policy.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/education',
    name: 'Education',
    component: Education,
    props: true,
    meta: {
      layout: BaseLayout,
      title: 'coc.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/forms',
    name: 'Forms',
    component: Forms,
    props: true,
    meta: {
      layout: BaseLayout,
      title: 'coc.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/store',
    name: 'SelectStore',
    component: SelectStore,
    meta: {
      layout: BaseLayout,
      title: 'store.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/store/dalvik',
    name: 'ProductsDalvik',
    component: Products,
    props: {
      selectedLocation: LOCATION_DALVIK,
    },
    meta: {
      layout: BaseLayout,
      title: 'store.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
  {
    path: '/store/akureyri',
    name: 'ProductsAkureyri',
    component: Products,
    props: {
      selectedLocation: LOCATION_AKUREYRI
    },
    meta: {
      layout: BaseLayout,
      title: 'store.pageTitle',
      isSecure: true,
      roles: ['ROLE_EMPLOYEE'],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(hasAccessGuard);

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = `${i18n.t(to.meta.title)}`;
  } else {
    document.title = 'Samherji hf.';
  }

  console.log(process.env.VUE_APP_TEST_TITLE);

  if(to.name === 'Login' && process.env.NODE_ENV === 'development') {
    document.title = process.env.VUE_APP_TEST_TITLE + ' ' + document.title;
  }
});

export default router
