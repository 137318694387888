<template>
  <v-app>
    <side-navigation :show="showSideNavigation" @input="onInput"/>
    <app-bar :selectedLocation="selectedLocation" @toggle-side-navigation="showSideNavigation = !showSideNavigation"></app-bar>
    <transition
        name="fade"
        mode="out-in"
    >
      <slot />
    </transition>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import SideNavigation from "@/components/common/SideNavigation";
import AppBar from "@/components/common/AppBar";
import AppFooter from "@/components/common/AppFooter";
export default {
  name: "BaseLayout",
  components: {AppFooter, AppBar, SideNavigation},
  props: {
    selectedLocation: {
      type: [String, Boolean],
      default: false
    },
  },
  watch: {
    $route() {
      this.showSideNavigation = false
    }
  },
  data() {
    return {
      showSideNavigation: false,
    }
  },
  methods: {
    onInput(val) {
      if(this.showSideNavigation !== val) {
        this.showSideNavigation = val;
      }
    }
  },
}
</script>

<style>
layout[name="BaseLayout"] {
  min-height: calc(100% - 56px);
}

.v-main {
  min-height: 100%;
}
</style>
