<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <slot/>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "LoginLayout"
}
</script>

<style scoped>

</style>
