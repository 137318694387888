import apiService from './apiService';

export default {
    authIntervalId: null,

    async createSigning() {
        const response = await apiService.post(
            `${process.env.VUE_APP_API_URL}/api/signing`,
            {},
            { withAuth: true }
        );
        return response.data;
    },
};
