<template>
  <layout name="BaseLayout">
    <v-main class="grey lighten-3">
      <v-container class="mt-5 news" :fluid="$vuetify.breakpoint.mdAndDown">
        <p class="text-h5">Select store</p>
        <v-divider class="mb-5"/>
        <v-row justify="center">
          <v-col
              cols="12"
              md="4"
          >
            <v-btn block :to="{ name: 'ProductsAkureyri' }">
              Akureyri
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
              cols="12"
              md="4"
          >
            <v-btn block :to="{ name: 'ProductsDalvik' }">
              Dalvik
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </layout>
</template>

<script>
import Layout from "@/components/layout/Layout";

export default {
  name: "SelectStore.vue",
  components: {Layout},
}
</script>

<style scoped>

</style>
